import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//Text editor
import Loading from '@dynapp/wc-api-loading';
import dyncMessages from './plugins/dync-messages.js';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import DyncPopovers from 'dync-popovers';
import DyncAuth from 'dync-auth';
import Tooltip from 'vue-directive-tooltip'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'
import VueGtag from "vue-gtag";
import DyncSocial from 'dync-social'

Vue.use(Tooltip, {
  autoHide: false,
  delay: { hide: 0 },
  'class': 'tooltip-wip'
});

Vue.use(VueGtag,
  {
    pageTrackerTemplate(to) {
      var object = {}

      var title = to.name
      if (to.name === 'article') {
        object = store.state.simpleArticles.find(item => item.id === to.params.itemId) || {};
        title = "Article: "
        title += object.title || to.name
      }
      else if (to.name === 'category') {
        object = store.state.headerCategories.find(item => item.id === to.params.itemId) || {};
        title = "Category: "
        title += object.en || object.sv || to.name

      }
      console.log(title);
      return {
        page_title: title,
        page_path: to.path
      }
    },
    includes: [
      {
        id: "G-K48BX1GEBL"
      }
    ],
    config: { id: "G-CEJ4B06QV9" },
    enabled: false
  },
  router)

Vue.use(DyncPopovers, { vuetify, dyncMessages });
Vue.use(Loading, { store });
Vue.use(DyncSocial, {store})
Vue.use(DyncAuth, {
  store, router,
  registerLogin: false,
  authType: 'local',
  authDataObject: 'web',
  usersDataObject: 'web'
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  dyncMessages,
  render: h => h(App)
}).$mount('#app')
