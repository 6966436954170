export default {
  // Place your English messages here
  browsing:{
    home: 'Home',
    selectLanguage: 'Language',
    changeLanguage: "Change language",
    languageChange: "Language changed to English",
    expandNavigation: "Expand navigation",
    closeNavigation: "Close navigation",
    expandAccessibility: "Expand accessibility menu",
    closeAccessibility: "Close accessibility menu",
    accessibilityTitle: "Accessibility settings",
    contrast: "Contrast:",
    textSize: "Text size:",
    setTextSize: {
      standard: "Set standard text size",
      medium: "Set medium text size",
      large: "Set large text size"
    },
    setColorTheme: {
      standard: "Set standard theme",
      greyscale: "Set greyscale theme",
      yellowBlack: "Set high contrast theme"
    },
    goHome: "To landing page"
  },
  footer:{
    other: "Other",
    aboutUs: "About us",
    admin: "Admin",
    involved: "People Involved",
    contact: "Contact",
    followUs: "Follow us on Social media \n@safeparasport ",
    linkToTwitter: "Link to twitter",
    linkToInstagram: "Link to instagram",
    goHome: "To ladingpage",
    copyright: "Ⓒ2022 Lunds Universitet. Powered by WIP and Synk Studio.",
    resetcookie: "Cookies",
    acceptcookies: "Accept cookies",
    cookieInfo: "This website uses cookies to improve its services. ",
    cookieIgnore: "Deny",
    cookiereadmore: "Read more",
    readpolicy: "Read policy",
    cookiepolicy: "We use Google Analytics to collect information about how visitors use our website. This information helps us to improve our website and our services. You can refuse the use of cookies by denying cookies. But please note that if you do, some parts of our website may not function properly. Google Analytics uses cookies to collect this information. By using our website, you consent to us using these cookies. We process all personal information collected through Google Analytics in accordance with our data protection policies and our privacy policy."
  },
  article:{
    summary: "Summary",
    author: "Written by ",
    contents: "Contents",
    startAudio: "Start audio playback",
    stopAudio: "Stop audio playback",
    jumpSection: "Jump to section "
  },
  category: {
    article: 'Article',
    moreArticles: "More topics within the same category",
    goToCategory: "Go to category"
  },
  involved:{
    authors: {
      title: "Authors",
      seeMore: "See all authors"
    },
    projectLeaders: {
      title: "Project leaders",
      seeMore: "See all project leaders"
    },
    developers:{
      title: "Web developers",
      seeMore: "See all web developers"
    },
    seeLess: "Show less"
  },
  general: {
    search: 'Search',
    searchField: 'Search field',
    searchFailed: 'Failed to complete search',
    searchEmpty: 'No matches',
    searchAll: 'Show all results',
    searchResults: 'Search results "{0}"',
    searchResultsCount: '{0} results'
  },
  home: {
    title: 'Training and health in para sport',
    text: 'For increased knowledge about safe and healthy participation in para sport',
    not_sure_title: 'Not quite sure what you are looking for?',
    followinstagram: "Follow us on Instagram"
  },
  admin: {
    sysadmin: 'SysAdmin',
    indexAll: {
      button: 'Index All',
      confirm: {
        title: 'Index All',
        message: 'This will re-index the search engine. Are you sure you want to this?'
      },
      success: 'Index all complete',
      error: 'Failed to index all'
    }
  }
};
