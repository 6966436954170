<template lang="html">
  <div >
    <v-btn
    class="mr-8 mr-lg-0"
      :style="{
        paddingTop: '4px',
        opacity: $store.state.sizeTheme === 'size-one' ?'100%' : '75%'
      }"
      color="white"
      @click="setSizeTheme('size-one')"
      text
      :aria-label="$t('browsing.setTextSize.standard')"
      :title="$t('browsing.setTextSize.standard')"
      fab
      :x-large="onMobile"
    >
      <v-img src="@/assets/icons/ic_A_button.svg" :height=" onMobile?31:19" contain></v-img>
    </v-btn>
    <v-btn
    class="mr-8 mr-lg-0"
      bottom
      :style="{
        fontSize: onMobile ? '3.5rem': '2.2rem',
        paddingTop: '4px',    
        fontWeight: 'bolder',    
        opacity: $store.state.sizeTheme === 'size-two' ?'100%' : '75%'
      }"
      color="white"
      tile
      text
      @click="setSizeTheme('size-two')"
      :aria-label="$t('browsing.setTextSize.medium')"
      :title="$t('browsing.setTextSize.medium')"
      fab
      :x-large="onMobile"
    >
      <v-img src="@/assets/icons/ic_A_button.svg" :height=" onMobile?46:29" contain></v-img>
    </v-btn>
    <v-btn
      :style="{
        fontSize: onMobile ? '5rem':'3.0rem',
        paddingTop: '4px',
        opacity: $store.state.sizeTheme === 'size-three' ? '100%' : '75%'
      }"
      color="white"
      text
      @click="setSizeTheme('size-three')"
      :aria-label="$t('browsing.setTextSize.large')"
      :title="$t('browsing.setTextSize.large')"
      fab
      :x-large="onMobile"
    >
      <v-img src="@/assets/icons/ic_A_button.svg" :height=" onMobile?61:38" contain></v-img>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'TextSizeSelector',
  props: {
    onMobile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setSizeTheme(value = "size-one") {
      this.$store.commit("setSizeTheme", value)
      this.$emit("clicked", value)
    }
  }
}
</script>

<style lang="css" scoped>
.v-btn:deep{
  font-weight: 900 !important;
}
</style>
