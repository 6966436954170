<template>
  <div class="mx-n3">
    <h2 class="mx-3">{{title}}</h2>
    <v-container fluid
      class="mt-md-4">
      <v-row justify="start">
        <v-col :cols="cols"
          :sm="sm"
          :md="md"
          :lg="lg"
          :xl="xl"
          v-for="item, i in calcedItems"
          :key="i">
          <template>
            <v-card flat color="transparent"
              tile>
              <v-row>
                <v-col cols="4"
                v-if="showImage"
                  md="12">
                  <v-img aspect-ratio="0.75"
                    v-if="item.image"
                    :src="$store.getters['involved/getAttachmentUrl'](item.id,item.image)">
                  </v-img>
                  <v-img aspect-ratio="0.75"
                    v-else
                    src="@/assets/paraweb_default_involved.jpg">
                  </v-img>
                </v-col>

                <v-col style="padding-top: 0px; margin-top: 5px">
                  <v-card-text class="px-0 py-0">
                    <h5 class="px-0 py-0"> {{item.name}}</h5><br>
                    <body if="item.subtitle"
                      class="px-0 py-0 mt-n4 mb-4">{{item.subtitle}}</body>
                    <div class="text-caption-1"> {{item.text}}</div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-col>
        <v-col cols="12" v-if="items.length > itemsLimit">
          <v-btn block
            tile
            depressed
            height="80"
            color="white"
            outlined
            @click="limitItems = !limitItems"
            class="button primary--text">
            <h3>  {{limitItems ? seMoreText : $t("involved.seeLess")}}</h3>
            <v-icon color="ctaDark"
              class="ml-4"> {{limitItems ? 'mdi-arrow-down' : 'mdi-arrow-up'}} </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "involvedCard",
  props: {
    itemsLimitSettings:{
      type: Object,
      default: () =>{
        return {
          default: "12",
          xs: "3",
          sm: "3",
          md: "8",
          lg: "12",
          xl: "12"
        }
      }
    },
    showImage:{
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    seMoreText: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => { return [] }
    },
    cols: {
      type: String,
      default: "12"
    },
    sm: {
      type: String,
      default: "10"
    },
    md: {
      type: String,
      default: "3"
    },
    lg: {
      type: String,
      default: "2"
    },
    xl: {
      type: String,
      default: "2"
    }
  },
  data() {
    return {
      limitItems: true
    }
  },
  computed: {
    calcedItems() {
      if (this.limitItems){
        return this.items.slice(0, this.itemsLimit)
      }
      return this.items

    },
    itemsLimit(){
      return this.itemsLimitSettings[this.$vuetify.breakpoint.name] || this.itemsLimitSettings.default
    }
  }
}
</script>

<style scoped>
.button {
  border: 4px solid;
  background-color: transparent;
  border-color: #A72336 !important;
}
</style>
