<template lang="html">
  <ItemsView
    storeModule="categories"
    titleMultiple="Categories"
    titleSingle="Category"
    :tableProps="{itemsPerPage: 10}"
    @items="items = $event"
    :headers="[{text:'Svenska', value: 'sv'},{text:'English', value: 'en'}]"
    orderable
  >
    <template #itemForm="{item, creating}">
      <div  v-for="language in $store.state.languages" :key="language.value">
        <v-text-field
          :label="language.title"
          v-model="item[language.value]"
          @input="setId($event, item, language.value, creating)">
        </v-text-field>
        <v-text-field
          :label="language.title + ' description'"
          v-model="item[language.value+'description']"
          @input="setId($event, item, language.value, creating)">
        </v-text-field>
      </div>


        <v-switch label="Hidden" v-model="item.hidden"></v-switch>
    </template>
  </ItemsView>

</template>

<script>
import
{
  ItemsView
}
from "dync-baseitem"
export default
{
  name:"Categories",
  components:
  {
    ItemsView
  },
  data()
  {
    return {
      items: []
    }
  },
  methods: {
    setId(value, item, language, creating) {
      if (creating && value && language == 'en') {
        var id = (
          value
          .toLowerCase()
          .trim()
          .split('')
          .map((a) => {
            return (
              a.replace(/ /, '-')
              .replace(/[åä]/, 'a')
              .replace(/[ö]/, 'o')
              .replace(/[^a-z0-9\-_]/, '')
            );
          })
          .join('')
        );
        this.$set(item, 'id', id);
        console.log(id);
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
