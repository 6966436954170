<template lang="html">
  <div class="">
    <v-main class="mx-md-6">
      <div class="mt-6 mb-12 px-2 d-flex align-end">
        <router-link :to="{ name: 'home' }">
          <v-img
            src="@/assets/im_logo_dark.svg"
            max-width="250"
            contain
          />
        </router-link>
        <h3 class="ml-6">Admin</h3>
        <v-spacer />
        <v-btn
          color="cta"
          class="white--text"
          @click="logout"
          :loading="logoutLoading"
        >
          Log out
        </v-btn>
      </div>
      <router-view/>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'

export default {
  name: 'AdminRoot',
  components: {
    Footer
  },
  computed: {
    logoutLoading() {
      return this.$store.getters.isLoadingFor('auth.logout');
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(
        () => {
          this.$router.push({name: 'home'});
        },
        () => {
          this.$dialog({
            title: 'Failed',
            message: 'Failed to log out. Try again later or contact support.'
          });
        }
      );
    }
  }
}
</script>

<style lang="css" scoped>
</style>
