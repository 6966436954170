<template>
  <div>
    <!--Audio buttons-->
    <div v-if="!state.loading"
      style=" background-color: #A72336; z-index: 2; position: fixed; right: 0; width: 44px;"
      class="">
      <div class="sticky">
        <v-btn large
          icon
          :aria-label="$t('article.startAudio')"
          @click="playArticle"
          class="text-color-light">
          <v-icon>{{ speaking ? 'mdi-pause' : 'mdi-play' }}</v-icon>
        </v-btn>
        <v-btn large
          icon
          :aria-label="$t('article.stopAudio')"
          class="text-color-light"
          @click="stopArticle">
          <v-icon>mdi-stop</v-icon>
        </v-btn>
      </div>
    </div>
    <div class='hidden-lg-and-up my-12'></div>

    <base-item storeModule="articles"
      class="maxglobalwidth"
      :itemId="itemId"
      @item="item = $event"
      @state="state = $event">

      <template #default="{ item }">

        <div v-if="!item.title && state.loading"
          class="">
          <v-progress-linear indeterminate
            height="10"
            rounded></v-progress-linear>
        </div>
        <v-container v-else
          fluid>
          <page-header class="mt-n12 mb-8"
            :previous-items="[{ text: categoryName, dst: '/category/' + item.category }]"
            :current-item="item.title">
          </page-header>
          <v-row>
            <!-- Chapter index Mobile-->
            <v-app-bar style="z-index: 3"
              scroll-threshold="15"
              color="transparent"
              class="mt-14 hidden-lg-and-up contentbar"
              fixed
              extension-height="0"
              flat
              :hide-on-scroll="true">
              <v-btn color="darkblue"
                block
                height="100%"
                :ripple="false"
                @click="expandContent">
                <h4 class="text-color-light ">{{ $t("article.contents") }}</h4>
                <v-spacer></v-spacer>
                <v-icon color="ctaLight">{{ !contentExpanded ? 'mdi-arrow-down' : 'mdi-arrow-up' }}</v-icon>
              </v-btn>
              <template #extension>
                <v-toolbar color="primary"
                  extension-height="auto"
                  height="0">
                  <template #extension>
                    <v-expand-transition tile>
                      <v-card v-show="contentExpanded"
                        flat
                        class="contentbar"
                        width="100%"
                        color="darkblue">
                        <v-card v-for="(item, i) in item.headers"
                          flat
                          :key="item + i"
                          @click="JumpToChapter(item.ref)"
                          height="auto"
                          :color="item.ref == focusedHeader ? 'primary' : 'darkblue'"
                          tabindex="0"
                          @keydown.enter.exact="JumpToChapter(item.ref)"
                          :aria-label="$t('article.jumpSection') + item.title"
                          class="d-flex py-2 pl-8 white--text">
                          <h4 style="word-wrap: break-word !important"
                            class="text-color-light "> {{ item.ref === "easy-to-read-summary" ? $t("article.summary") :
                                item.title
                            }} </h4>
                        </v-card>
                      </v-card>
                    </v-expand-transition>
                  </template>
                </v-toolbar>
              </template>
            </v-app-bar>
            <!-- Chapter index desktop-->

            <v-col cols="3"
              class="hidden-md-and-down">
              <v-card color="surface"
                flat
                min-width="200"
                max-height="70vh"
                class="sticky rounded-0 d-flex flex-column py-4 pl-4 pr-0
                 text-p overflow-y-auto">
                <v-card v-for="(item, i) in item.headers"
                  flat
                  clickable
                  @click="JumpToChapter(item.ref)"
                  @keydown.enter.exact="JumpToChapter(item.ref)"
                  :aria-label="$t('article.jumpSection') + item.title"
                  tabindex="0"
                  :key="item + i"
                  class="d-flex py-2 mb-1 px-2 text-color transparent"
                  :class="{ 'darkblue white--text': item.ref == focusedHeader }"> {{ item.ref === "easy-to-read-summary"
                      ? $t("article.summary") : item.title
                  }} </v-card>
              </v-card>
            </v-col>
            <v-col class="hidden-md-and-down"
              cols="0"
              lg="1"></v-col>
            <!-- Article -->
            <v-col cols="12"
              lg="7"
              md="8"
              class="article"
              id="fullArticle">
              <h1>{{ item.title }}</h1>
              <h5 class="my-4"
                v-if="item.author">
                <router-link :to="{ name: 'peopleinvolved' }">{{ $t("article.author") + " " + item.author }}
                </router-link>
              </h5>
              <div class="my-6 article"
                ref="easy-to-read-summary"
                id="easy-to-read-summary"
                v-html="item.summary"></div>
              <v-img v-if="item.headerimage"
                :alt="item.headerimagedesc"
                :aspect-ratio="1.9"
                :src="$store.getters['articles/getAttachmentUrl'](item.id, item.headerimage)">
              </v-img>
              <div v-if="item.headerimage"
                class="mb-6 mt-1 text-caption-1">{{ item.headerimagedesc }}</div>
              <div class="mt-6 "
                style="overflow-wrap: break-word;"
                id="div-html-document"
                v-html="item.htmldoc"></div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </base-item>
    <v-container fluid
      class="maxglobalwidth">
      <category v-if="item.category"
        :itemId="item.category"
        :viaArticleId="itemId"></category>
    </v-container>
  </div>
</template>

<script>
import { BaseItem } from "dync-baseitem";
import Category from "./Category.vue"
import PageHeader from "./PageHeader.vue";
export default {
  name: "ReadArticle",
  components: {
    BaseItem,
    Category,
    PageHeader
  },
  props: {
    itemId: {}
  },
  data() {
    return {
      item: {},
      state: {},
      htmlText: "",
      contentExpanded: false,
      element: {},
      utterance: new SpeechSynthesisUtterance,
      speechSynth: window.speechSynthesis,
      focusedHeader: null,
      speaking: false,
      paused: false
    };
  },
  watch: {
    "$dyncMessages.locale": function (value) {
      this.switchLanguage(value)
    },
    $route() {
      this.stopArticle();
      this.handleQueryScroll();
    },
    item() {
      this.setFocusedHeader();
    },
    'state.loading'() {
      this.handleQueryScroll();
    }
  },
  computed: {
    categoryName() {
      let category = this.getObjectByKey(this.$store.state.headerCategories, 'id', this.item.category)
      if (!category) {
        return "Category"
      }
      return category[this.$dyncMessages.locale]
    },
    speakingParts() {
      var elem = document.getElementById("fullArticle");
      if (!elem) return []
      var elementText = elem.textContent || elem.innerText;
      var lang = "sv-SV"
      if (this.item.language == 'en') {
        lang = 'en-US'
      }
      else if (this.item.language == 'sv') {
        lang = 'sv-SV'
      }
      var parts = []
      elementText.split(".").forEach((string) => {
        var utter = new SpeechSynthesisUtterance
        utter.text = string
        utter.lang = lang
        utter.onend = () => {
          if (!this.speechSynth.pending) {
            this.speaking = false
          }
        }
        parts.push(utter)
      })
      return parts
    }
  },
  methods: {
    togglePlay() {

      if (this.speechSynth.speaking) {
        if (this.speechSynth.paused) {
          this.resumeArticle()
          return
        }
        else if (!this.speechSynth.paused) {
          this.pauseArticle()
          return
        }
      }
      console.log("playing");
    },
    playArticle() {
      if (this.speechSynth.speaking) {
        if (this.paused) {
          this.resumeArticle()
          return
        }
        else if (!this.paused) {
          this.pauseArticle()
          return
        }
      }
      this.speechSynth.cancel();
      this.speaking = true
      this.speakingParts.forEach((item) => {
        this.speechSynth.speak(item)
      })
      //this solves issue with chrome not reading long texts

    },
    stopArticle() {

      console.log("Stoped");
      this.speechSynth.cancel(this.utterance)
      this.speaking = false
      this.paused = false
    },
    resumeArticle() {
      this.speechSynth.resume()
      console.log("Resumed");
      this.paused = false
      this.speaking = true
    },
    pauseArticle() {
      this.speaking = false
      console.log("Paused");
      this.paused = true
      this.speechSynth.pause(this.utterance)
    },
    getVoice(lang, secLang) {

      var voices = speechSynthesis.getVoices()
      for (var i in voices) {
        if (voices[i].lang == lang || voices[i].lang == secLang) {
          console.log(voices[i]);
          return voices[i];
        }
      }

      for (var j in voices) {
        if (voices[j].lang == 'en-US' || voices[j].lang == 'en') {
          console.log(voices[j]);
          return voices[j];
        }
      }
      return null
    },
    expandContent() {
      this.contentExpanded = !this.contentExpanded
    },
    switchLanguage() {
      if (!this.item.otherlanguages) {
        return
      }
      var other = this.getObjectByKey(this.item.otherlanguages, "language", this.$dyncMessages.locale)
      if (!other) {
        return
      }
      this.$router.replace({ name: `article`, params: { itemId: other.id } })
    },
    JumpToChapter(id) {
      window.scrollTo({
        top: this.getSectionScrollPosition(id),
        behavior: "smooth"
      });
      this.expandContent()
    },
    headerObject(elem) {
      if (!elem.textContent) {
        return
      }
      return {
        id: elem.id,
        title: elem.textContent
      }
    },
    getObjectByKey(array, key, value) {
      if (!array || !key || !value) {
        return
      }
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
          return array[i];
        }
      }
      return null;
    },
    getSectionScrollPosition(id) {
      var element = document.getElementById(id);
      if (!element) {
        return 0;
      }
      var headerOffset = 120;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      return offsetPosition;
    },
    setFocusedHeader() {
      var pageY = window.pageYOffset;
      var headers = (this.item || {}).headers || [];
      var result = headers[0];
      for (var i = 0; i < headers.length; i++) {
        var header = headers[i];
        var sectionPosition = this.getSectionScrollPosition(header.ref);
        if (sectionPosition <= (pageY + 1)) {
          result = header;
        } else {
          break;
        }
      }
      this.focusedHeader = (result || {}).ref || null;
    },
    handleQueryScroll() {
      if (this.$route.query.chp) {
        this.$nextTick(() => {
          this.JumpToChapter(this.$route.query.chp);
        });
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.setFocusedHeader);

    this.setFocusedHeader();
    this.handleQueryScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setFocusedHeader);
  }
};
</script>

<style scoped lang="scss">
.sticky {
  position: sticky;
  top: 130px;
  z-index: 3;
}

.contentbar:deep {
  padding: 0;

  .v-card {
    border-radius: 0px !important;
  }

  .v-btn {
    width: 100%;
    border-radius: 0px !important;
  }

  .v-toolbar__content {
    padding: 0;
    margin: 0;
  }

  .v-toolbar__extension {
    padding: 0;
  }
}

.article:deep {

  p,
  strong,
  span,
  em {
    margin-bottom: 0px;
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
  }

  h3 {
    color: var(--text-color-header) !important;
  }

  img{    
    object-fit: cover;
    max-width: 100%;
    width: 100%;
  }
  
  iframe {
    object-fit: cover;
    aspect-ratio: 1.9;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }

  li[data-list=bullet] {
    list-style-type: disc !important;
  }

  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  word-wrap: break-word;
}
</style>
<old>.article:deep
.article:deep .ql-align-justify {
  margin-bottom: 5px;
}

.article:deep iframe {
  object-fit: cover;
  aspect-ratio: 1.9;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.article:deep span {
  background-color: transparent !important;
}


</old>
