<template lang="html">
  <div class="mb-16">
    <ArticlesEdit v-if="$store.getters['auth/hasRole']('admin')"></ArticlesEdit>
    <CategoriesEdit v-if="$store.getters['auth/hasRole']('admin')"></CategoriesEdit>
    <PeopleInvolvedEdit v-if="$store.getters['auth/hasRole']('admin')"></PeopleInvolvedEdit>
    <Users v-if="(
      $store.getters['auth/hasRole']('users')
      || $store.getters['auth/hasRole']('sysadmin')
    )"></Users>
    <v-dialog v-model="socialDialog"
      width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary lighten-2 mt-3"
          dark
          x-large
          v-bind="attrs"
          v-on="on">
          Hantera sociala medier
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Social mediera
        </v-card-title>

        <v-card-text>
          <ManageSocialPages :showFBLogin="true"></ManageSocialPages>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
            text
            @click="socialDialog = false">
            Stäng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container v-if="$store.getters['auth/hasRole']('sysadmin')"
      fluid>
      <v-card>
        <v-card-title>
          {{ $t('admin.sysadmin') }}
        </v-card-title>
        <v-card-text>
          <v-btn class="my-4"
            @click="indexAll"
            :loading="$store.getters.isLoadingFor('articles.indexall')">
            {{ $t('admin.indexAll.button') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ArticlesEdit from '../components/admin/ArticlesEdit.vue'
import CategoriesEdit from '../components/admin/CategoriesEdit.vue'
import PeopleInvolvedEdit from '../components/admin/PeopleInvolvedEdit.vue'
import { Users } from 'dync-users'
import { ManageSocialPages } from 'dync-social'
export default {
  components: {
    ArticlesEdit,
    CategoriesEdit,
    PeopleInvolvedEdit,
    ManageSocialPages,
    Users
  },
  data() {
    return {
      socialDialog: false
    }
  },
  methods: {
    indexAll() {
      this.$confirm({
        title: this.$t('admin.indexAll.confirm.title'),
        message: this.$t('admin.indexAll.confirm.message')
      }).then(
        () => {
          this.$store.dispatch('indexAllArticles').then(
            () => {
              this.$snackbar({ message: this.$t('admin.indexAll.complete'), theme: 'success' });
            },
            () => {
              this.$dialog({ message: this.$t('admin.indexAll.error') });
            }
          );
        },
        () => { }
      )
    }
  }
}
</script>

<style lang="css" scoped></style>
