<template lang="html">
  <div class="search-match" @blur="$emit('blur')">
    <router-link
      :to="{name: 'article', params: {itemId: item.doc.id}}"
      @blur="$emit('blur')"
      class="search-article-link"
    >
      <div class="search-article-title darkblue px-3 py-1">
        <div class="d-flex align-center">
          <div
            class="search-article-title-text white--text text-h5"
            v-html="processMatch(item.doc.title, 'ctaLight--text')"
          ></div>
          <v-spacer />
        </div>
      </div>
    </router-link>

    <div class="search-article-headers">
      <div v-for="section in matchedSections">
        <router-link
          :to="{name: 'article', params: {itemId: item.doc.id}, query: {chp: section.header.ref}}"
          @blur="$emit('blur')"
          class="search-article-header-link"
        >
          <div class="search-article-header grey lighten-2 px-3 py-1">
            <div class="d-flex align-center">
              <div
                class="search-article-header-text black--text text-h6"
                v-html="
                  section.header.ref == 'easy-to-read-summary'
                  ? $t('article.summary')
                  : processMatch(section.header.title)
                "
              ></div>
              <v-spacer />
              <v-icon class="match-cta-icon" color="cta">mdi-arrow-right</v-icon>
            </div>
          </div>
          <div class="">
            <div
              v-for="match in section.matches"
              class="search-article-text helvetica px-3 pt-2 pb-4 text-body-1"
              style="color: var(--text-color);"
              v-html="(match.cutStart ? '' : '...') + processMatch(match.cut) + (match.cutEnd ? '' : '...')"
            ></div>
            <div
              v-if="section.matches.length == 0"
              class="search-article-text helvetica px-3 pt-2 pb-4 text-body-1"
              style="color: var(--text-color);"
              v-text="getSectionStart(section)"
            ></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchMatch',
  props: {
    item: {}
  },
  methods: {
    escapeHtml(value) {
      return value
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },
    processMatch(value, matchClass) {
      value = this.escapeHtml((value || '').trim());
      if (!this.item.searchValue) {
        return value;
      }
      return value.replace(this.searchRegex, '<span class="search-mark '+(matchClass || 'cta--text')+'">$&</span>');
    },
    getSectionStart(section) {
      var text = section.text || '';
      var result = text.split(' ').slice(0, 20).join(' ');
      if (result.length < text.length) {
        result += '...';
      }
      return result;
    }
  },
  computed: {
    searchRegex() {
      var searchString = (this.item.searchValue || '').trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      searchString = '(' + searchString.split(' ').join('|') + ')';
      return new RegExp(searchString, 'gi')
    },
    matchedSections() {
      return ((this.item || {}).sections || []).filter((s) => s.headerMatch || s.matches.length);
    }
  }
}
</script>

<style lang="scss" scoped>
.search-match:deep {
  .search-article-link{
    text-decoration: none !important;

    &:focus-within {
      .search-article-title-text {
        text-decoration: underline;
      }
    }
  }
  .search-article-header-link {
    text-decoration: none !important;

    &:focus-within {
      .search-article-header-text {
        text-decoration: underline;
      }
    }
  }
}
</style>
