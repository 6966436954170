<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
//import './scss/_variables.scss'
export default {
  name: 'App',
  data() {
    return {
      //
    }
  },
  watch: {
    $route() {
      speechSynthesis.cancel()
    },
    '$store.state.sizeTheme': function() {
      this.applyTheme();
    },
    '$store.state.colorTheme': function() {
      this.applyTheme();
    }
  },
  methods: {
    applyTheme() {
      // Apply "font size theme"
      document.documentElement.setAttribute("size-theme", this.$store.state.sizeTheme);

      // Apply "color theme"
      document.documentElement.setAttribute("color-theme", this.$store.state.colorTheme);
    }
  },
  mounted() {
    this.$store.dispatch('FetchHeaderCategories');
    this.$store.dispatch('FetchArticles')
    this.applyTheme();
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url("./assets/fonts/Helvetica.ttf") format("truetype");
}
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');
@import "./scss/text-color.scss";
.vue-tooltip.tooltip-wip {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .5);
  color: #444;
  font-family: 'Roboto';
  line-height: 1.5rem;
  white-space: pre-wrap;
  z-index:2000;
}
.vue-tooltip.tooltip-wip .tooltip-arrow {
  display: none;
}
</style>
