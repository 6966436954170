<template lang="html">
  <div class="" style="min-height: 100%; 
  display: flex;
    flex-direction: column;" >
    <NavBar></NavBar>
    <v-main style="padding-top: 110px; " >
      <div >
      <router-view class="my-lg-16 my-0 mx-1 mx-sm-3 mx-md-6 mx-lg-11  " />
      </div>

    </v-main>
    <Footer class="footer" />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'PublicRoot',
  components: {
    NavBar,
    Footer
  }
}
</script>

<style lang="scss">
.maxglobalwidth{
  max-width: 1780px;
  margin: 0 auto;
  align-self: center;
}
.mainfooter{
  align-items: baseline;
    width: 100%;
    bottom: 0;
}
</style>
