<template>
  <div>
    <v-app-bar app
      :hide-on-scroll="true"
      color="secondary">
      <v-app-bar-nav-icon class="d-md-none"
        @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-container class="my-0 mx-1 mx-sm-3 mx-md-6 mx-lg-7 "
        style="padding: 0px"
        fluid>
        <v-row class="maxglobalwidth">
          <v-col cols="auto"
            align-self="center">
            <router-link :to="{ name: 'home' }"
              :aria-label="$t('browsing.goHome')">
              <v-img clickable
                src="@/assets/im_header_logo.svg"
                max-width="180"
                min-width="140" />
            </router-link>
          </v-col>
          <v-col v-if="this.$route.name !== 'home'"
            align-self="center"
            style="min-width: 250px; max-width: 320px; flex-grow: 3;">
            <search-bar class="mt-2"
              :scrollOnFocus="false"
              :attachClose="false"
              :menuZIndex="8"></search-bar>
          </v-col>
          <v-spacer />
          <v-col cols="auto"
            align-self="center"
            class="mr-1">
            <text-size-selector></text-size-selector>
          </v-col>
          <v-col cols="auto"
            align-self="center"
            class="mr-4">
            <color-selector></color-selector>
          </v-col>
          <v-col cols="auto"
            align-self="center"
            class="mr-0">
            <v-select :items="$store.state.languages"
              item-text="short"
              class="custom languageSelect mt-6 mr-0"
              append-icon="mdi-chevron-down"
              color="ctaLight"
              item-color="ctaDark"
              :value="this.$dyncMessages.locale"
              :aria-label="$t('browsing.changeLanguage')"
              @input="$emit('changeLanguage', $event)">
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <!-- Row with categories -->
      <template #extension>
        <div class="testClass ">
          <div style="min-width: 4000px;" class="mx-1 mx-sm-3 mx-md-2 mx-lg-7 ">
            <v-container fluid>
              <v-row>
                <v-col v-for="item in $store.state.headerCategories"
                  cols="auto"
                  :key="item.id"
                  class="pa-0">
                  <v-btn tile
                    text
                    style="letter-spacing: 1px"
                    :class="isSelected(item) ? 'text-caption-1-bold px-3 text-color-light' : 'text-caption-1 px-3 text-color-light'"
                    @click="
                      $router.push({ name: `category`, params: { itemId: item.id } })
                    "> {{ item[$dyncMessages.locale] }} </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import SearchBar from './SearchBar.vue';
import TextSizeSelector from './TextSizeSelector.vue';
import ColorSelector from './ColorSelector.vue';

export default {
  name: "NavigationBar",
  data() {
    return {
      group: "",
      headerCategories: []
    };
  },
  methods: {
    isSelected(item) {
      if (this.$route.params.itemId === item.id) {
        return true;
      }
      if (this.$route.name === 'article') {
        if (this.$store.getters["articles/getItemById"](this.$route.params.itemId)) {
          if (
            this.$store.getters["articles/getItemById"](
              this.$route.params.itemId
            ).category === item.id
          ) {
            return true;
          }
        }
      }
      return false;
    }
  },
  components: {
    SearchBar,
    TextSizeSelector,
    ColorSelector
  }
};
</script>

<style scoped lang="scss">
.testClass {
  max-width: 1840px;
  margin: 0 auto 0 auto;
}

.languageSelect.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none !important;
}

.languageSelect.v-text-field>.v-input__control>.v-input__slot:after {
  border-style: none !important;
}

.languageSelect:deep {
  max-width: 70px;

  .theme--light {
    color: #FF92A1;
  }

  .v-select__selection {
    color: white !important;
  }

}
</style>
