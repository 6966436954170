<template>
  <v-row class="mx-0" justify="space-between" >
    <breadcrumbs :previous-items="previousItems" :current-item="currentItem"></breadcrumbs>
    <v-spacer />
    <div>
      <slot></slot>
    </div>
  </v-row>
</template>

<script>
import Breadcrumbs from './Breadcrumbs.vue'

export default {
  props: {
    previousItems: {
      type: Array
    },
    currentItem: {
      type: String
    }
  },
  components: {
    Breadcrumbs
  }
}
</script>

<style scoped>
</style>
