<template lang="html">
  <div class="ma-4">
    <v-btn :to="{name: 'admin'}"
      class="primary--text mb-4"
      text>
      <v-icon left>mdi-arrow-left</v-icon>BACK
    </v-btn>
    <ItemView storeModule="articles"
      :itemId="itemId"
      title="Article"
      @items="log($event)"
      @submit="$router.replace({path: '/admin'})"
      @close="$router.replace({path: '/admin'})">
      <template #default="{item, creating, required}">
        <v-container fluid dense>
          <v-row class="ma-n6">
            <v-col>
              <v-select label="Language"
                outlined
                :items="$store.state.languages"
                v-model="item.language"
                item-text="title"
                item-value="value"
                :rules="[required]"></v-select>
            </v-col>
            <v-col>
              <v-select label="Category"
                outlined
                :items="categories"
                v-model="item.category"
                item-text="en"
                item-value="id"
                :rules="[required]"></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-checkbox class="mt-n3" label="Show on landing page"
          v-model="item.showonlandingpage"
        ></v-checkbox>
        <v-autocomplete label="Article in other languages"
          small
          outlined
          :items="$store.state.simpleArticles || []"
          multiple
          deletable-chips
          v-model="item.otherlanguages"
          item-text="title"
          return-object>
          <template v-slot:item="{item, attrs, on}">
            <v-list-item v-on="on"
              v-bind="attrs"
              #default="{ active }">
              <v-list-item-action>
                <v-checkbox :ripple="false"
                  :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters>{{ item.title }} | {{ item.language }} </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-text-field label="Title"
          outlined
          v-model="item.title"
          @input="setId($event, item, creating)"
          :rules="[required]"></v-text-field>
        <v-text-field label="Author"
          outlined
          v-model="item.author"
          :rules="[required]"></v-text-field>
        <image-input class="mb-4"
          label="Icon"
          outputType="png"
          accept="png"
          imageBGColor="#a3a3a3"
          imageBGColorAlt="#636363"
          v-model="item.iconimage"
          :nameToUrl="(name) => $store.getters['articles/getAttachmentUrl'](item.id,name)"></image-input>
        <image-input class="mb-4"
          label="Header image"
          v-model="item.headerimage"
          :nameToUrl="(name) => $store.getters['articles/getAttachmentUrl'](item.id,name)"></image-input>
        <v-text-field label="Image desciption"
          outlined
          v-model="item.headerimagedesc"
          :rules="item.headerimage ? [required] : []"></v-text-field>
        <h4 class="mt-4">Easy to read summary</h4> Use Heading 3 for chapters <VueEditor :editorToolbar="toolbar"
          label="Intro text"
          :editorOptions="editorSettings"
          hint="Easy to read summary"
          style="margin-bottom: 5rem;"
          outlined
          v-model="item.summary"></VueEditor>
        <h4 class="mt-8">Article</h4> Use Heading 3 for chapters <VueEditor :editorToolbar="toolbar"
          id="editor1"
          style="height: 45rem !important; margin-bottom: 5rem;"
          :editorOptions="editorSettings"
          v-model="item.htmldoc"></VueEditor>
      </template>
    </ItemView>
  </div>
</template>

<script>
import { ImageInput } from 'dync-imageinput';
import { ItemView } from "dync-baseitem";
import { VueEditor, Quill } from "vue2-editor-table";
import ImageResize from "quill-image-resize-vue";

Quill.register("modules/imageResize", ImageResize);

export default {
  name: "ArticleEdit",
  components: {
    ItemView,
    VueEditor,
    ImageInput
  },
  props: ["viewid", "itemId", "title", "options"],
  data() {
    return {
      toolbar: [[{
        header: [false, 3, 4, 5]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
      [{
        align: ""
      }, {
        align: "center"
      }, {
        align: "right"
      }, {
        align: "justify"
      }], ["blockquote", "code-block"], [{
        list: "ordered"
      }, {
        list: "bullet"
      }, {
        list: "check"
      }], [{
        indent: "-1"
      }, {
        indent: "+1"
      }], // outdent/indent
      [{
        color: []
      }, {
        background: []
      }], // dropdown with defaults from theme
      ["link", "image", "video"], ["clean"] // remove formatting button
      ],
      maxLength: [
        v => v.length <= 200 || "Max 200 characters"
      ],
      categories: [],
      editorSettings: {
        modules: {
          imageResize: {}
        }
      }
    };
  },
  methods: {
    log(logValue) {
      console.log(logValue);
    },
    setId(value, item, creating) {
      if (creating && value) {
        var id = (
          value
            .toLowerCase()
            .trim()
            .split('')
            .map((a) => {
              return (
                a.replace(/ /, '-')
                  .replace(/[åä]/, 'a')
                  .replace(/[ö]/, 'o')
                  .replace(/[^a-z0-9\-_]/, '')
              );
            })
            .join('')
        );
        this.$set(item, 'id', id);
      }
    }
  },
  mounted() {
    this.$store.dispatch('categories/fetchItems').then((res) => {
      this.categories = res.data.categories
    })
  }
};
</script>

<style lang="css" scoped>
#editor1 {}

.article:deep img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}
</style>
