<template>
    <div >
        <v-container fluid class="maxglobalwidth">

        <page-header class="mt-n12 mb-8"
            :previous-items="[{ text: $t('footer.aboutUs'), dst: '/aboutus' }]"
            :current-item="$t('footer.involved')">
        </page-header>
        <InvolvedCard :title="$t('involved.projectLeaders.title')" :seMoreText="$t('involved.projectLeaders.seeMore')"
            :items="projectLeaders"></InvolvedCard>
        <InvolvedCard class="mt-md-16" :title="$t('involved.authors.title')" :seMoreText="$t('involved.authors.seeMore')"
            :items="authors"></InvolvedCard>
        <InvolvedCard class="mt-md-16" :title="$t('involved.developers.title')" :seMoreText="$t('involved.developers.seeMore')" md="4" xl="4" lg="4" :showImage="false"
            :items="developers"></InvolvedCard>
            </v-container>
    </div>
</template>
<script>
import InvolvedCard from '../components/InvolvedCard.vue'
import PageHeader from '../components/PageHeader.vue'

export default {
    data() {
        return {
            items: []            
        }
    },
    methods:{
        sortbyName(array){
            return array.sort((a, b) => {
                let x = a.name.toLowerCase();
                let y = b.name.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            })
        }
    },
    computed: {
        developers() {
            return this.sortbyName(this.items.filter(item => item.type === "developer"))
        },
        authors() {
            return this.sortbyName(this.items.filter(item => item.type === "author"))
        },
        projectLeaders() {
            const array = this.sortbyName(this.items.filter(item => item.type === "projectleader"))
            array.forEach(function (item, i) {
                if (item.name === "Kristina Fagher") {
                    array.splice(i, 1);
                    array.unshift(item);
                }
            });
            return array
        },
        breakpoint() {
            console.log(this.$vuetify.breakpoint.name)
            return this.$vuetify.breakpoint.name
        }
    },
    mounted() {
        this.$store.dispatch('involved/fetchItems').then((res) => {
            console.log(res.data.involved)
            this.items = res.data.involved
        }
        )

    },
    components: {
        InvolvedCard,
        PageHeader
    }
}
</script>