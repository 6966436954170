import Vue from 'vue'
import Vuex from 'vuex'
import {createModule, apiUrl, sortByKey} from '@dynapp/wc-api-generic'
import axios from 'axios'

const _createModule = (sing, multi, dataObject) => {
  return createModule({
    entitySingular: sing,
    entityPlural: multi,
    dataObject
  });
}

Vue.use(Vuex)

var hasLocalStorageSupport = false
if (typeof(window.Storage) !== "undefined") {
  hasLocalStorageSupport = true;
}

const convertToPlain = (html) => {
    // Create a new div element
    var tempDivElement = document.createElement("div");
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;
    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export default new Vuex.Store({
  state: {
    headerCategories: [],
    simpleArticles: [],
    searchResult: {
      payload: null,
      response: [],
      result: []
    },
    sizeTheme: hasLocalStorageSupport ? localStorage.sizeTheme || "size-one" : "size-one",
    colorTheme: hasLocalStorageSupport ? localStorage.colorTheme || "standard" : "standard",
    languages: [
      {
        title: "Svenska",
        value: "sv",
        short: "SVE"
      },
      {
        title: "English",
        value: "en",
        short: "ENG"
      }
    ]
  },
  mutations: {
    setCategoriesState(state,data){
      state.headerCategories = data
    },
    setArticleState(state, data) {
      state.simpleArticles = data
    },
    setSizeTheme(state, value){
      state.sizeTheme = value
      if (hasLocalStorageSupport) {
        // Store to local to remember to next reload or visit
        localStorage.sizeTheme = value;
      }
    },
    setColorTheme(state, value){
      state.colorTheme = value
      if (hasLocalStorageSupport) {
        // Store to local to remember to next reload or visit
        localStorage.colorTheme = value;
      }
    },
    setSearchResult(state, value){
      state.searchResult = value;
    }
  },
  actions: {
    FetchHeaderCategories ({commit}) {
      return axios.get(apiUrl({dataObject: 'web'}, 'getCategories'))
        .then((response) => {
          commit('setCategoriesState', response.data || []);
        });
    },
    FetchArticles({ commit }) {
      return axios.get(apiUrl({ dataObject: 'web' }, 'getSimpleArticles'))
        .then((response) => {
          commit('setArticleState', response.data || []);
        });
    },
    searchArticles({ commit }, payload) {
      var _payload = {
        q: payload.search,
        lang: payload.lang
      };
      commit('startLoading', 'articles.search');
      var promise = axios.post(
        apiUrl({dataObject: 'web'}, 'search'),
        _payload
      );
      promise.then(
        (resp) => {
          commit('endLoading', 'articles.search');

          // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
          // $& means the whole matched string
          var searchString = (payload.search || '').trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          var searchWords = searchString.split(' ');

          var result = [];
          resp.data.response.docs.forEach((doc) => {
            // Split article into text sections per header/chapter
            var sections = [];
            var headers = JSON.parse(doc.headers || '[]');
            if (doc.summary) {
              sections.push({
                header: headers[0],
                text: convertToPlain(doc.summary)
              });
            }
            var htmldocSections = [(doc.htmldoc || [''])[0]];
            headers.map((h) => {
              htmldocSections = htmldocSections.map(s => s.split(
                new RegExp('<h[123].+?id="'+h.ref+'".*?>.+?</h[123]>', 'gmi')
              )).reduce((a, s) => a.concat(s), []);
            });
            htmldocSections = htmldocSections.slice(1);
            htmldocSections.map((section, index) => {
              sections.push({
                header: headers[index + (doc.summary ? 1 : 0)],
                text: convertToPlain(section)
              });
            });

            // Find each match in every section
            var maxMatches = 250;
            sections.forEach((section) => {
              section.headerMatch = (
                section.header.ref != 'easy-to-read-summary'
                && searchWords.some(s => section.header.title.search(new RegExp(s, 'gi')) != -1)
              );
              section.matches = [];
              var sectionLastIndex = section.text.length;
              searchWords.forEach((s) => {
                let iterator = section.text.matchAll(
                  new RegExp(s, 'gmi')
                );
                let match = iterator.next();
                let matchIndex = 0;
                while (!match.done && matchIndex < maxMatches) {
                  // Include up to 10 words before and after match, max 100 chars
                  var cutStartIndex = (
                    match.value.index
                     - (
                       match.value.input.slice(
                         Math.max(0, match.value.index - 100),
                         match.value.index
                       )
                       .split(' ')
                       .slice(-11)
                       .join(' ')
                     ).length
                   );
                  var cutEndIndex = (
                    match.value.index
                    + match.value[0].length
                    + (
                      match.value.input.slice(
                        match.value.index + match.value[0].length,
                        match.value.index + 100
                      )
                      .split(' ')
                      .slice(0, 11)
                      .join(' ')
                    ).length
                  );
                  section.matches.push({
                    matchIndex: match.value.index,
                    cutStartIndex: cutStartIndex,
                    cutEndIndex: cutEndIndex,
                    cutStart: cutStartIndex == 0,
                    cutEnd: cutEndIndex == sectionLastIndex,
                    cut: match.value.input.slice(cutStartIndex, cutEndIndex)
                  });
                  match = iterator.next();
                  matchIndex += 1;
                }
              });

              section.matches.sort(sortByKey('matchIndex'));

              // Combine matches that overlap each other
              var processedMatches = [];
              for (var i = 0; i < section.matches.length;) {
                var combinedMatch = null;
                for (var j = i+1; j < section.matches.length; j++) {
                  if ((combinedMatch || section.matches[i]).cutEndIndex >= section.matches[j].cutStartIndex) {
                    if (!combinedMatch) {
                      combinedMatch = Object.assign({
                        combineCount: 0
                      }, section.matches[i]);
                      processedMatches.push(combinedMatch);
                    }
                    combinedMatch.combineCount += 1;
                    combinedMatch.cut = combinedMatch.cut + section.matches[j].cut.slice(
                      combinedMatch.cutEndIndex - section.matches[j].cutStartIndex
                    );
                    combinedMatch.cutEndIndex = section.matches[j].cutEndIndex;
                    combinedMatch.cutEnd = section.matches[j].cutEnd;
                  } else {
                    break;
                  }
                }
                if (combinedMatch) {
                  i = j;
                } else {
                  processedMatches.push(section.matches[i]);
                  i += 1;
                }
              }
              section.matches = processedMatches;
            });

            result.push({
              doc: doc,
              searchValue: payload.search,
              sections: sections
            });
          });

          commit('setSearchResult', {
            payload: payload,
            response: resp.data.response.docs,
            result: result
          });
        },
        () => {
          commit('endLoading', 'articles.search');
          commit('setSearchResult', {
            payload: null,
            response: [],
            result: []
          });
        }
      );
      return promise;
    },
    indexAllArticles({ commit }) {
      commit('startLoading', 'articles.indexall');
      return axios.post(apiUrl({ dataObject: 'web' }, 'index-all'), {}).then(
        () => {
          commit('endLoading', 'articles.indexall');
        },
        () => {
          commit('endLoading', 'articles.indexall');
        }
      );
    }
  },
  modules: {
    articles: _createModule('article', 'articles', 'web'),
    categories: _createModule('category', 'categories', 'web'),
    involved: _createModule('involved', 'involved', 'web'),
  }
})
