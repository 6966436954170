<template lang="html">
  <div class="d-flex flex-column" style="min-height: 100%;">
    <v-main class="flex-grow-1">
      <div class="d-flex flex-column" style="min-height: 100%;">
        <div class="d-flex mt-8 mx-4">
          <v-spacer />
          <v-btn text :to="{name: 'home'}">Close<v-icon right>mdi-close</v-icon></v-btn>
        </div>
        <div class="flex-grow-1"></div>
        <v-container>
          <div class="mx-auto" style="max-width: 430px;">
            <v-img
              class="mx-auto"
              src="@/assets/im_logo_dark.svg"
              max-width="250"
              contain
            />
            <div class="mt-12 mb-16" style="min-height: 400px;">
              <h3 class="text-center">
                Login Admin
              </h3>

              <v-text-field
                label="Email"
                type="email"
                v-model="email"
                class="mt-8"
                outlined
                :disabled="loading"
                @keydown.enter="submit"
              ></v-text-field>

              <v-text-field
                label="Password"
                type="password"
                v-model="password"
                class="mt-n2"
                outlined
                :disabled="loading"
                @keydown.enter="submit"
              ></v-text-field>

              <v-btn
                color="cta"
                class="white--text mt-2"
                width="100%"
                height="50"
                :loading="loading"
                @click="submit"
              >
                Log in
              </v-btn>
            </div>
          </div>
        </v-container>
        <div class="flex-grow-1"></div>
      </div>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'

export default {
  name: 'LoginView',
  components: {
    Footer
  },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoadingFor('auth.login');
    }
  },
  methods: {
    submit() {
      this.$store.dispatch('auth/login', {
        username: this.email,
        password: this.password
      }).then(
        () => {
          var returnRoute = this.$store.getters['auth/returnRoute'];
          if (returnRoute) {
            this.$router.push(returnRoute);
          } else {
            this.$router.push({name: 'admin'});
          }
        },
        () => {
          this.$dialog({
            title: 'Failed',
            message: 'Failed to login. Check that email and password is correct and try again.'
          });
        }
      );
    }
  },
  mounted() {
    if (this.$store.getters['auth/loginStatus'] == 'LOGGED_IN') {
      this.$router.push({name: 'admin'});
    }
  }
}
</script>

<style lang="css" scoped>
</style>
