import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Article from '../components/Article.vue'
import Category from '../components/Category.vue'
import AboutUs from '../views/AboutUs.vue'
import PeopleInvolved from '../views/PeopleInvolved.vue'
import FontTest from '../views/FontTest.vue'
import Admin from '../views/Admin.vue'
import ArticleEdit from '../components/admin/ArticleEdit.vue'
import AdminRoot from '../views/AdminRoot.vue'
import PublicRoot from '../views/PublicRoot.vue'
import Login from '../views/Login.vue'
import SearchView from '../views/SearchView.vue'
Vue.use(Router)

export default new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  routes: [
    {
      path: '/',
      name: 'public-root',
      redirect: {
        name: 'home'
      },
      component: PublicRoot,
      meta: {
        public: true
      },
      children: [
        {
          path: '/home',
          name: 'home',
          component: Home
        },
        {
          path: '/FontTest',
          name: 'FontTest',
          component: FontTest
        },
        {
          path: '/aboutus',
          name: 'aboutus',
          component: AboutUs
        },
        {
          path: '/peopleinvolved',
          name: 'peopleinvolved',
          component: PeopleInvolved
        },
        {
          path: '/article/:itemId',
          name: 'article',
          props: true,
          component: Article,
        },
        {
          path: '/category/:itemId',
          name: 'category',
          props: true,
          component: Category,
        },
        {
          path: '/search-articles',
          name: 'search-articles',
          props: (route) => ({
            ...route.query
          }),
          component: SearchView,
        }
      ]
    },
    {
      name: 'admin-root',
      path: '/admin',
      redirect: {
        name: 'admin'
      },
      component: AdminRoot,
      meta: {
        public: false
      },
      children: [
        {
          path: '/admin/home',
          name: 'admin',
          component: Admin
        },
        {
          path: '/admin/article-edit/:itemId',
          name: 'article-edit',
          props: true,
          component: ArticleEdit,
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        public: true
      },
      component: Login
    }
  ]
})
