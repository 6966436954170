<template>
  <div class="pa-0 ma-0">
    <v-row class="mt-12 row"
      no-gutters
      wrap
      v-if="!articles.length <= 0">
      <v-col cols="12"
        sm="6"
        md="4"
        lg="3"
        class="collum"
        v-for="(item, i) in articles "
        :key="i">
        <v-card flat
          tile
          class="d-flex flex-column white--text rounded-0 "
          height="auto"
          min-height="220"
          tabindex="0"
          color="transparent"
          :aria-label="$t('category.article') + ' ' + item.title"
          :to="{ name: 'article', params: { itemId: item.id } }">
          <div style="background-color: #F4F5F5">
            <v-img aspect-ratio="1.7">
              <v-img aspect-ratio="1.7"
                v-if="item.headerimage"
                :src="item.headerimage ? imageUrl(item, 'headerimage') : '@/assets/im_header_logo.svg'">
              </v-img>
            </v-img>
          </div>
          <v-container fluid
            class="mb-4 pa-2">
            <v-row no-gutters>
              <v-col cols="11">
                <h3 class="text-color-header"> {{ item.title }} </h3>
              </v-col>
              <v-col cols="1"
                align-self="end">
                <v-icon color="ctaDark">mdi-arrow-right</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { apiUrl } from '@dynapp/wc-api-generic'

export default {
  props: {
    articles: {
      type: Array,
      default: () => { return [] }
    },

    viaArticleId: {
      type: String
    }
  },
  methods: {
    imageUrl(item, key) {
      let url = apiUrl({ dataObject: 'web' }, 'getattachment/article' + "/" + item.id + "/" + item[key] + "/" + "720")
      return url
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/row-gutters.scss";
</style>