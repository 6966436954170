<template lang="html">
  <ItemsView storeModule="articles" titleMultiple="Articles"
    :tableProps="{itemsPerPage: 10}"
    @items="items = $event"
    :headers="[{text:'Title', value: 'title'},{text:'Category', value: 'category'},{text:'Language', value: 'language'}]"
    @add="$router.push({ name: `article-edit`, params: { itemId: 'create'} }) "
    @click:item="$router.push({ name: `article-edit`, params: { itemId: $event.id} }) "
    :itemDialog="false"
  >
    <template #item.category="{item}">
      {{categoryName(item.category)}}
    </template>
  </ItemsView>
</template>

<script>
import
{
  ItemsView
}
from "dync-baseitem"
export default
{
  components:
  {
    ItemsView
  },
  data()
  {
    return {
      items: []
    }
  },
  methods:{
    categoryName(categoryId){
      //this is to prevent null errors on firts open
        if (this.$store.getters['categories/getItemById'](categoryId)) {
          return this.$store.getters['categories/getItemById'](categoryId).en
        }
      return "None"
    }
  }
}
</script>

<style lang="css" scoped>

</style>
