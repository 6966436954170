export default {
  // Place your Swedish messages here
  browsing:{
    home: 'Hem',
    selectLanguage: 'Språk',
    changeLanguage: 'Byt språk',
    languageChange: "Språk bytt till svenska",
    expandNavigation: "Expandera navigation",
    closeNavigation: "Stäng navigation",
    expandAccessibility: "Expandera tillgänglighetsmeny",
    closeAccessibility: "Stäng tillgänglighetsmeny",
    accessibilityTitle: "Tillgänglighetsinställningar",
    contrast: "Kontrast:",
    textSize: "Textstorlek:",
    setTextSize: {
      standard: "Sätt standard textstorlek",
      medium: "Sätt mellanstor textstorlek",
      large: "Sätt stor textstorlek"
    },
    setColorTheme: {
      standard: "Sätt standard tema",
      greyscale: "Sätt gråskale-tema",
      yellowBlack: "Sätt högkontrast-tema"
    },
    goHome: "Till startsidan"
  },
  footer:{
    other: "Annat",
    aboutUs: "Om oss",
    admin: "Admin",
    involved: "Personer involverade",
    contact: "Kontakt",
    followUs: "Följ oss på sociala medier \n@safeparasport",
    linkToTwitter: "Länk till twitter",
    linkToInstagram: "Länk till instagram", 
    copyright: "Ⓒ2022 Lunds Universitet. Powered by WIP and Synk Studio.",
    resetcookie: "Cookies",
    acceptcookies: "Acceptera cookies",
    cookieInfo: "Denna sidan använder cookies för att samla statistik.",
    cookieIgnore: "Neka",
    cookiereadmore: "Läs mer",
    readpolicy: "läs policy",

    cookiepolicy: "Vi använder Google Analytics för att samla in information om hur besökare använder vår webbplats. Denna information hjälper oss att förbättra vår webbplats och våra tjänster.  Du kan neka användningen av cookies genom att neka till cookies. Men vänligen notera att om du gör det kan vissa delar av vår webbplats kanske inte fungera som de ska.  Google Analytics använder cookies för att samla in denna information. Genom att använda vår webbplats samtycker du till att vi använder dessa cookies. Vi behandlar all personlig information som samlas in genom Google Analytics i enlighet med våra riktlinjer för dataskydd och vår sekretesspolicy."
  },
  article: {
    summary: "Lättläst sammanfattning",
    author: "Skriven av ",
    contents: "Innehåll",
    startAudio: "Starta ljud uppspelning",
    stopAudio: "Stopa ljud uppspelning",
    jumpSection: "Hoppa till sektion "
  },
  category: {
    article: "Artikel",
    moreArticles: "Fler artiklar i samma kategori",
    goToCategory: "Gå till kategorin"
  },
  involved: {
    authors: {
      title: "Författare",
      seeMore: "Se alla författare"
    },
    projectLeaders: {
      title: "Projektledning",
      seeMore: "Se alla projektledare"
    },
    developers: {
      title: "Webbutveckling",
      seeMore: "Se alla webbutvecklare"
    },
    seeLess: "Visa mindre"
  },
  general: {
    search: 'Sök',
    searchField: 'Sökfält',
    searchFailed: 'Sökningen kunde inte slutföras',
    searchEmpty: 'Inga träffar',
    searchAll: 'Visa alla resultat',
    searchResults: 'Sökresultat "{0}"',
    searchResultsCount: '{0} resultat'
  },
  home: {
    title: 'Träning och hälsa för aktiva inom parasport',
    text: 'För ökad kunskap om paraidrottares förutsättningar till ett långt och starkt idrottsliv.',
    not_sure_title: 'Vet du inte riktigt vad du letar efter?',
    followinstagram: "Följ oss på Instagram"
  },
  admin: {
    sysadmin: 'SysAdmin',
    indexAll: {
      button: 'Indexera Alla',
      confirm: {
        title: 'Indexera Alla',
        message: 'Det här kommer att om-indexera sökmotorn. Är du säker på att du vill göra detta?'
      },
      success: 'Indexering slutförd',
      error: 'Misslyckades att indexera alla'
    }
  }
};
