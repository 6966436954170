<template>
  <div class="mb-16">
    <v-container fluid
      class=" maxglobalwidth">
      <page-header v-if="false" :previous-items="[{dst: '/home', text: 'Home'}]"
        :current-item="category[$dyncMessages.locale]">
      </page-header>
      <v-row>
        <v-col cols="12"
          md="7">
          <h1 v-if="viaArticleId"
            class="mt-12">{{ $t('category.moreArticles') }}</h1>
          <h1 v-else-if="category">{{ category[$dyncMessages.locale] }}</h1>
          <div class="mt-2 text-body-large">{{ category[$dyncMessages.locale + 'description'] }}</div>
        </v-col>
      </v-row>
      <CategoryCards :articles="articles"
        :viaArticleId="viaArticleId"></CategoryCards>
    </v-container>
    <v-btn class=" text-color-light btn-text "
    style="float: right; padding: 24px; height: 50px; letter-spacing: 0.04em;"
      v-if="viaArticleId"
      :to="{ name: 'category', params: { itemId } }"
      depressed
      :block="$vuetify.breakpoint.smAndDown ? true : false"
      color="cta">{{ $t('category.goToCategory') }}</v-btn>
  </div>
</template>

<script>
import CategoryCards from './CategoryCards.vue'
import PageHeader from './PageHeader.vue'
export default
  {
    props: {
      itemId: {
        type: String
      },
      viaArticleId: {
        type: String
      }
    },
    data() {
      return {
        cols: {
          xs: {
            "size-one": "12",
            "size-two": "12",
            "size-three": "12"
          },
          sm: {
            "size-one": "6",
            "size-three": "12"
          },
          md: {
            "size-one": "4",
            "size-two": "4",
            "size-three": "6"
          },
          lg: {
            "size-one": "2",
            "size-two": "4",
            "size-three": "6"
          },
          xl: {
            "size-one": "2",
            "size-two": "4",
            "size-three": "6"
          }
        }
      }
    },
    methods: {

      //calculates nr of cols with text size
      calcCols(brp, standard = "6") {
        if (this.cols[brp]) {
          let brpSettings = this.cols[brp]
          if (brpSettings[this.$store.state.sizeTheme]) {
            return brpSettings[this.$store.state.sizeTheme]
          }
        }
        return standard
      }
    },
    computed: {
      category() {
        let id = this.itemId;
        var category = this.$store.state.headerCategories.find(obj => {
          return obj.id === id
        })
        return category || false;
      },
      articles() {
        var category = this.category;
        if (!category) {
          return []
        }
        console.log("computing articles")
        let lanArticles = category[this.$dyncMessages.locale + 'articles'] || []
        let generalArticles = category['generalarticles'] || []
        let articles = lanArticles.concat(generalArticles)
        console.log(articles);
        if (this.viaArticleId) {
          // Remove article that these are shown via
          articles = articles.filter(obj => {
            return obj.id !== this.viaArticleId
          }).slice(0,4)
        }
        console.log(articles);
        return articles
      }
    },

    components: {
      CategoryCards,
      PageHeader
    }
  }
</script>

<style scoped lang="scss">

</style>
