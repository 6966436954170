<template>
  <nav :class="classNames">
    <ul class="breadcrumbs__items text-caption-1">
      <li class="breadcrumbs__item " v-for="item in previousItems">
        <span class="breadcrumbs__link-container">
          <router-link :to="item.dst" class="breadcrumbs__link">{{ item.text }}</router-link>
        </span>
      </li>
      <li class="breadcrumbs__item">
        <span class="breadcrumbs__current">{{ currentItem }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['previousItems', 'currentItem', 'classNames']
}
</script>

<style lang="scss" scoped>
.breadcrumbs__items {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

.breadcrumbs__item {
  white-space: nowrap;
  display: inline-block;
}

.breadcrumbs__link-container, .breadcrumbs__link {
  color: #b6b6b6;
  text-decoration: none;
}

.breadcrumbs__link:hover {
  text-decoration: underline;
}

.breadcrumbs__link-container::after {
  content: "/";
  color: var(--text-color);
  margin: 0 .3rem;
}
</style>
