<template >
  <v-card class="d-flex secondary rounded-0 pt-8 pt-md-12 pb-4">

    <v-container fluid
      class="mx-1 mx-sm-3 mx-md-6 mx-lg-11 ">
      <div class="maxglobalwidth">
        <v-row>
          <v-col cols="12">
            <router-link :to="{ name: 'home' }"
              :aria-label="$t('browsing.goHome')">
              <v-img class="justify-start"
                src="@/assets/im_header_logo.svg"
                max-width="200"
                contain />
            </router-link>
          </v-col>
          <v-col cols="auto"
            class="mr-14 mt-7">
            <h4 class="text-h4 white--text">{{ $t("footer.other") }}</h4>
            <div class="ml-n4 mt-2 d-flex flex-column align-start">
              <v-btn text
                color="white"
                class="text-body-1 py-6"
                :to="{ name: 'aboutus' }">
                {{ $t("footer.aboutUs") }}
              </v-btn>
              <v-btn text
                color="white"
                class="text-body-1 py-6"
                :to="{ name: 'peopleinvolved' }">
                {{ $t("footer.involved") }}
              </v-btn>
              <v-btn text
                color="white"
                class="text-body-1 py-6"
                :to="{ name: 'admin' }">
                {{ $t("footer.admin") }}
              </v-btn>
              <v-btn text
                color="white"
                class="text-body-1 py-6"
                @click="resetcookies">
                {{ $t("footer.resetcookie") }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="auto"
            class="mt-0 mt-md-7">
            <h4 class="text-h4 white--text">{{ $t("footer.contact") }}</h4>
            <div class="px-n3 ml-n3 mt-2 d-flex flex-column align-start ">
              <v-btn text
                color="ctaLight"
                class="text-body-1 py-6"
                tile
                small
                href="mailto:kristina.fagher@med.lu.se"> kristina.fagher@med.lu.se </v-btn>
            </div>
          </v-col>

          <v-spacer />

          <v-col cols="auto"
            class="mt-7">
            <h4 style="white-space: pre-line"
              class="text-h4 white--text">{{ $t("footer.followUs") }}</h4>
            <div class="mt-2 d-flex align-start ">

              <v-btn icon
                x-large
                color="white"
                label="Instagram"
                :aria-label="$t('footer.linkToInstagram')"
                href="https://instagram.com/safeparasport?igshid=YmMyMTA2M2Y="
                target="_blank">
                <v-icon color="white"> mdi-instagram </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div class="d-flex justify-center text-color-light mt-16"
          style="text-align: center"> {{ $t('footer.copyright') }} </div>
        <CookieLaw @accept="optinout()"
          :buttonText="$t('footer.acceptcookies')"
          :message="$t('footer.cookieInfo')"
          :buttonDecline="true"
          :buttonDeclineText="$t('footer.cookieIgnore')"
          ref="cookielaw"
          theme="dark-lime">
          <div slot-scope="props"
            style=" width: 100%;"
            class="d-flex">

            <v-row>
              <v-col cols="auto">
                <h4 class=" text-color-light">
                  {{ $t('footer.cookieInfo') }}
                  <v-dialog v-model="policydialog"
                    width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn-text ml-3"
                        depressed
                        small
                        v-bind="attrs"
                        v-on="on">
                        {{ $t('footer.cookiereadmore') }}
                      </v-btn>
                    </template>
                    <v-card style="background-color: var(--background-color);">
                      <v-card-title class="text-h5  lighten-2">
                        Privacy Policy
                      </v-card-title>
                      <v-card-text>
                        <br>
                        <p>
                          {{ $t("footer.cookiepolicy") }}
                        </p>
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          depressed
                          class=" text-color-light btn-text"
                          color="cta"
                          target="_blank"
                          href="http://mdz.wip.se/privacy/">
                          {{ $t('footer.readpolicy') }}
                        </v-btn>
                        <v-btn class="btn-text"
                          depressed
                          @click="policydialog = false">
                          Stäng
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </h4>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn class=" text-color-light btn-text"
                  color="cta"
                  depressed
                  @click="props.accept"><span>{{ $t('footer.acceptcookies') }}</span></v-btn>

              </v-col>
              <v-col cols="auto">
                <v-btn class="btn-text"
                  depressed
                  @click="props.close"><span>{{ $t('footer.cookieIgnore') }}</span></v-btn>
              </v-col>

            </v-row>
          </div>

        </CookieLaw>
      </div>
    </v-container>

  </v-card>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
  data() {
    return {
      policydialog: false
    }
  },
  methods: {
    resetcookies() {
      this.$refs.cookielaw.revoke()
      this.$refs.cookielaw.open()
      this.optinout()
    },
    optinout() {
      if (this.$refs.cookielaw.isAccepted()) {
        this.$gtag.optIn()
        console.log('Opting in to cookies');
      }
      else {
        this.$gtag.optOut()
        console.log('Opting Out from cookies');
      }
    }
  },
  mounted() {
    if (!this.$refs.cookielaw.isAccepted()) {
      this.resetcookies()
    }
  },
  components: { CookieLaw }
}
</script>
<style lang="scss" >
.Cookie--dark-lime {
  background: #004987;
}

.Cookie--dark-lime .Cookie__button {
  padding: 0.625em 3.125em;
  color: white;
  border-radius: 0;
  border: 0;
  font-size: calc(1.2em * var(--size)) !important;
  background: #A72336;
}

.Cookie--dark-lime .Cookie__content {
  font-size: calc(1.2em * var(--size)) !important;
  color: var(--text-color-light);
}

.Cookie--dark-lime .Cookie__button--decline {
  font-size: calc(1.2em * var(--size)) !important;
  color: var(--text-color-light);
}

.Cookie--dark-lime .Cookie__button:hover {
  background: #FF92A1;
}
</style>