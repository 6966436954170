var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mr-8 mr-lg-0",style:({
      paddingTop: '4px',
      opacity: _vm.$store.state.sizeTheme === 'size-one' ?'100%' : '75%'
    }),attrs:{"color":"white","text":"","aria-label":_vm.$t('browsing.setTextSize.standard'),"title":_vm.$t('browsing.setTextSize.standard'),"fab":"","x-large":_vm.onMobile},on:{"click":function($event){return _vm.setSizeTheme('size-one')}}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ic_A_button.svg"),"height":_vm.onMobile?31:19,"contain":""}})],1),_c('v-btn',{staticClass:"mr-8 mr-lg-0",style:({
      fontSize: _vm.onMobile ? '3.5rem': '2.2rem',
      paddingTop: '4px',    
      fontWeight: 'bolder',    
      opacity: _vm.$store.state.sizeTheme === 'size-two' ?'100%' : '75%'
    }),attrs:{"bottom":"","color":"white","tile":"","text":"","aria-label":_vm.$t('browsing.setTextSize.medium'),"title":_vm.$t('browsing.setTextSize.medium'),"fab":"","x-large":_vm.onMobile},on:{"click":function($event){return _vm.setSizeTheme('size-two')}}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ic_A_button.svg"),"height":_vm.onMobile?46:29,"contain":""}})],1),_c('v-btn',{style:({
      fontSize: _vm.onMobile ? '5rem':'3.0rem',
      paddingTop: '4px',
      opacity: _vm.$store.state.sizeTheme === 'size-three' ? '100%' : '75%'
    }),attrs:{"color":"white","text":"","aria-label":_vm.$t('browsing.setTextSize.large'),"title":_vm.$t('browsing.setTextSize.large'),"fab":"","x-large":_vm.onMobile},on:{"click":function($event){return _vm.setSizeTheme('size-three')}}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ic_A_button.svg"),"height":_vm.onMobile?61:38,"contain":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }