<template lang="html">
  <div class="search-view mx-auto mb-16" style="max-width: 60rem;">

    <div class="d-flex align-center">
      <v-icon large color="cta" class="mr-2">mdi-magnify</v-icon>
      <h2 class="darkblue--text">{{$t('general.searchResults', [q])}}</h2>
    </div>

    <div class="d-flex align-center my-2 text-body-1">
      <v-spacer />
      <div>
        {{$t('general.searchResultsCount', [searchResult.length])}}
      </div>
    </div>

    <div
      v-if="searching"
      class="text-center text--secondary"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>

    <div
      v-else-if="searchResult.length"
      class="pa-0 surface"
    >
      <div v-for="match in searchResult">
        <search-match
          :item="match"
          class="pb-2"
        ></search-match>
      </div>
    </div>

    <div v-else>
      <div class="text-body-1 text-center my-2">
        {{$t('general.searchEmpty')}}
      </div>
    </div>
  </div>
</template>

<script>
import SearchMatch from '../components/SearchMatch.vue';

export default {
  name: 'SearchView',
  components: {
    SearchMatch
  },
  props: {
    q: {
      type: String
    }
  },
  watch: {
    q() {
      this.performSearch(this.q);
    }
  },
  methods: {
    performSearch(value) {
      var payload = {
        search: value,
        lang: this.$dyncMessages.locale
      };
      this.$store.dispatch('searchArticles', payload).then(
        () => {},
        (error) => {
          console.log(error);
          this.$snackbar({
            message: this.$t('general.searchFailed'),
            theme: 'error'
          });
        }
      );
    }
  },
  mounted() {
    if (!this.$store.state.searchResult.payload) {
      this.performSearch(this.q);
    }
  },
  computed: {
    searchResult() {
      return this.$store.state.searchResult.result;
    },
    searching() {
      return this.$store.getters.isLoadingFor('articles.search');
    }
  }
}
</script>

<style lang="css" scoped>
</style>
