import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import dyncMessages from './dync-messages.js';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => {
      return dyncMessages.translate(key, params)
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#004987',
        secondary: '#002A3A',
        accent: '#8c9eff',
        error: '#b71c1c',
        surface: '#f4f4f4',
        cta: '#A72336',
        ctaLight: '#FF92A1',
        ctaDark: '#A72336',
        darkblue: '#003A5D'
      },
    },
  },
});
