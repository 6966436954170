<template lang="html">
  <div style="background-color: #002A3A; width: auto; ">
    <NavigationBarMd @changeLanguage="changeLanguage($event)" class="hidden-md-and-down " />
    <NavigationBarXs @changeLanguage="changeLanguage($event)" class="hidden-lg-and-up" />
  </div>
</template>

<script>
import NavigationBarMd from './NavigationBarMd.vue'
import NavigationBarXs from './NavigationBarXs.vue'
export default {
  name: "NavBar",
  components: {
    NavigationBarMd,
    NavigationBarXs,
  },
  methods:{
    changeLanguage(event) {
      console.log(event)
      /*
      var localeIndex = this.$dyncMessages.locales.indexOf(this.$dyncMessages.locale);
      var newLocale = this.$dyncMessages.locales[
        (localeIndex + 1) >= this.$dyncMessages.locales.length ? 0 : localeIndex + 1
      ];*/
      this.$dyncMessages.locale = event;
      this.$snackbar({message: this.$t("browsing.languageChange"), theme: 'success'});
    },
  }
}
</script>

<style lang="css" scoped>
</style>
