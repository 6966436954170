var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mr-12 mr-lg-2 mt-3",style:({
      fontSize: '1.5rem',
      opacity: _vm.$store.state.colorTheme === 'standard' ?'100%' : '75%'
    }),attrs:{"aria-label":_vm.$t('browsing.setColorTheme.standard'),"title":_vm.$t('browsing.setColorTheme.standard'),"color":"black","icon":"","large":_vm.onMobile,"fab":""},on:{"click":function($event){return _vm.setColor('standard')}}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ic_standard.svg"),"height":!_vm.onMobile ? 42 : 'auto',"contain":""}})],1),_c('v-btn',{staticClass:"mr-12 mr-lg-2 mt-3",style:({
      fontSize: '1.5rem',
      opacity: _vm.$store.state.colorTheme === 'greyscale' ?'100%' : '75%'
    }),attrs:{"aria-label":_vm.$t('browsing.setColorTheme.greyscale'),"title":_vm.$t('browsing.setColorTheme.greyscale'),"large":_vm.onMobile,"icon":"","fab":""},on:{"click":function($event){return _vm.setColor('greyscale')}}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ic_greyscale.svg"),"height":!_vm.onMobile ? 42 : 'auto',"contain":""}})],1),_c('v-btn',{staticClass:"mr-12 mr-lg-0 mt-3",style:({
      fontSize: '1.5rem',
      opacity: _vm.$store.state.colorTheme === 'yellow-black' ?'100%' : '75%'
    }),attrs:{"aria-label":_vm.$t('browsing.setColorTheme.yellowBlack'),"title":_vm.$t('browsing.setColorTheme.yellowBlack'),"icon":"","large":_vm.onMobile,"fab":""},on:{"click":function($event){return _vm.setColor('yellow-black')}}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ic_yellowblack.svg"),"height":!_vm.onMobile ? 42 : 'auto',"contain":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }