<template>
  <div
    @keydown="checkFocus"
    @keydown.up.prevent.stop="focusPrev"
    @keydown.down.prevent.stop="focusNext"
    @keydown.esc.prevent.stop="close"
    class="search-bar d-flex mb-2"
    style="position: relative;"
  >
    <v-text-field
      v-model.trim="value"
      flat
      :height="!dark ? 52: 32"
      :label="$t('general.search')"
      ref="searchbar"
      :aria-label="$t('general.searchField')"
      class="search-input rounded-0 text-body-1"
      :dark="dark"
      
      :solo="!dark"
      :dense="!dark"
      :outlined="!dark"
      hide-details
      @focus="onFocus"
      @blur="checkFocus"
      @input="checkFocus"
      @keydown.enter.prevent.stop="$router.push(fullSearchLink)"
      search-id="input"
      clearable
    >
      <template #prepend-inner>
          <v-icon :class="dark ? '' : ''" color="ctaDark">
            mdi-magnify
          </v-icon>
      </template>
    </v-text-field>
    <div ref="menuattach">
    </div>
    <v-menu
      offset-y
      tile
      :z-index="menuZIndex"
      :activator="($refs.searchbar || {}).$el"
      :_attach="$refs.menuattach"
      :value="hasFocus && !!value"
      :close-on-content-click="false"
      :open-on-click="false"
      :content-class="!value ? 'search-menu-hidden' : ''"
      @input="onMenuInput"
    >
      <v-card
        v-if="value"
        tile
        flat
        ref="searchcard"
        style="max-width: fit-content; min-width: 100%;"
        @keydown="checkFocus"
        @keydown.up.prevent.stop="focusPrev"
        @keydown.down.prevent.stop="focusNext"
        @keydown.esc.prevent.stop="close"
      >
        <v-card-text
          v-if="inputTimeout || searching"
          class="text-center"
        >
          <v-progress-circular indeterminate></v-progress-circular>
        </v-card-text>

        <v-card-text
          v-else-if="searchResult.length"
          class="pa-0 surface"
          style="overflow-y: auto;"
          :style="{
            'max-height': fullHeight ? 'calc(100vh - 200px)' : '50vh'
          }"
        >
          <div v-for="match in searchResult">
            <search-match
              :item="match"
              @blur="checkFocus"
              class="pb-2"
            ></search-match>
          </div>
        </v-card-text>

        <v-card-text v-else>
          <div class="text-body-1">
            {{$t('general.searchEmpty')}}
          </div>
        </v-card-text>

        <router-link
          v-if="!inputTimeout && !searching && searchResult.length"
          :to="fullSearchLink"
          class="d-flex py-4 px-4 cta white--text search-all"
          style="text-decoration: none;"
          ref="searchall"
          @blur="checkFocus"
        >
          <div class="">
            {{$t('general.searchAll')}}
          </div>
          <v-spacer />
          <v-icon color="white">mdi-arrow-right</v-icon>
        </router-link>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import SearchMatch from '../components/SearchMatch.vue';

export default {
  components: {
    SearchMatch
  },
  props: {
    dark: {
      type: Boolean,
      default: true
    },
    menuZIndex: {
      type: Number,
      default: 4
    },
    scrollOnFocus: {
      type: Boolean,
      default: true
    },
    attachClose: {
      type: Boolean,
      default: true
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: '',
      inputTimeout: null,
      hasFocus: false
    };
  },
  watch: {
    value(val) {
      // Wait to search, to avoid searching for every letter
      clearTimeout(this.inputTimeout);
      if (!val || val.length < 2) {
        return;
      }
      var currentInputTimeout = setTimeout(() => {
        this.performSearch(val);
        if (this.inputTimeout == currentInputTimeout) {
          this.inputTimeout = null;
        }
      }, 200);
      this.inputTimeout = currentInputTimeout;
    },
    $route() {
      // Close search on route change
      this.close();
      this.value = '';
    }
  },
  computed: {
    searchResult() {
      return this.$store.state.searchResult.result.slice(0, 5);
    },
    searching() {
      return this.$store.getters.isLoadingFor('articles.search');
    },
    fullSearchLink() {
      return {name: 'search-articles', query: {q: this.value}};
    }
  },
  methods: {
    onFocus() {
      this.checkFocus();
      if (this.scrollOnFocus) {
        this.jumpToSearch();
      }
    },
    jumpToSearch() {
      var element = this.$refs.searchbar.$el;
      var headerOffset = 130;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
    checkFocus() {
      // Timeout due to new focus not ready yet on blur event
      setTimeout(() => {
        this.hasFocus = (
          this.$el.contains(document.activeElement)
          || (this.$refs.searchcard && this.$refs.searchcard.$el.contains(document.activeElement))
        );
      }, 10)
    },
    onMenuInput(val) {
      if (!val) {
        this.close();
      }
    },
    stepFocus(step) {
      var current = document.activeElement;
      if (!current || !this.searchResult.length) {
        return;
      }
      var targets = [this.$refs.searchbar.$refs.input];
      targets = targets.concat(Array.from(
        this.$refs.searchcard.$el.querySelectorAll('.search-match a')
      ));
      targets.push(this.$refs.searchall.$el);

      var currentIndex = targets.indexOf(current);
      if (currentIndex == -1) {
        // Focus is somewhere else
        return;
      }
      var nextIndex = currentIndex + step;
      if (nextIndex == -1 || nextIndex == targets.length) {
        // At start or end
      } else {
        targets[nextIndex].focus();
      }
    },
    focusNext() {
      this.stepFocus(1);
    },
    focusPrev() {
      this.stepFocus(-1);
    },
    close() {
      this.focus();
      this.blur();
    },
    focus() {
      this.$refs.searchbar.focus();
    },
    blur () {
      this.$refs.searchbar.blur();
    },
    performSearch(value) {
      var payload = {
        search: value,
        lang: this.$dyncMessages.locale
      };
      this.$store.dispatch('searchArticles', payload).then(
        () => {},
        (error) => {
          console.log(error);
          this.$snackbar({
            message: this.$t('general.searchFailed'),
            theme: 'error'
          });
        }
      );
    }
  }
}
</script>


<style scoped lang="scss">
.search-bar:deep(.search-menu-hidden) {
  box-shadow: unset;
}

.search-all:focus {
  // font-weight: bold;
  text-decoration: underline !important;
}
</style>
