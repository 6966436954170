<template lang="html">
    <ItemsView storeModule="involved"
        titleMultiple="Involved"
        titleSingle="Involved"
        :tableProps="{itemsPerPage: 10}"
        @items="items = $event"
        defaultSort="type"
        :headers="[{text:'Namn', value: 'name'},{text:'Type', value: 'type',  sortable: true}]"
        >
        <template #itemForm="{item}">
            <v-select :items="types"
                v-model="item.type"
                label="Type"></v-select>
            <v-text-field v-model="item.name"
                label="Name"></v-text-field>
            <v-text-field v-model="item.subtitle" v-if="item.type === 'projectleader'"
                label="Subtitle"></v-text-field>
            <ImageInput v-model="item.image" v-if="item.type != 'developer'" :nameToUrl="(name) => $store.getters['involved/getAttachmentUrl'](item.id,name)"></ImageInput>
            <v-textarea label="Text" v-model="item.text" auto-grow ></v-textarea>
        </template>
    </ItemsView>
</template>

<script>
import { ImageInput } from 'dync-imageinput';

import {
    ItemsView
}
    from "dync-baseitem"
export default
    {
        name: "Involved",
        components:
        {
            ItemsView,
            ImageInput
        },
        data() {
            return {
                types: [
                    {
                        text: "Project leader",
                        value: "projectleader"
                    },
                    {
                        text: "Author",
                        value: "author"
                    },
                    {
                        text: "Web developer",
                        value: "developer"
                    }
                ],
                items: []
            }
        }
    }
</script>

<style lang="css" scoped>

</style>
