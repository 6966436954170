<template>
  <div>
    <v-app-bar app
      scroll-threshold="20"
      z-index="8"
      elevation="0"
      :hide-on-scroll="true"
      :extension-height="'auto'"
      color="secondary"
      class="appBarclass ">
      <v-toolbar dense
        flat
        elevation="0"
        tile
        class=" mx-4 mx-sm-6 mx-md-9 mx-lg-14 "
        color="secondary">
        <v-toolbar-title class="ma-0 pa-0">
          <router-link :to="{ name: 'home' }"
            :aria-label="$t('browsing.goHome')">
            <v-img clickable
              src="@/assets/im_header_logo.svg"
              max-width="150"
              contain />
          </router-link>
        </v-toolbar-title>
        <div class="px-4 hidden-xs-only">
          <search-bar class="px-4 mt-3"
            :scrollOnFocus="false"
            :attachClose="false"
            :menuZIndex="8"
            fullHeight></search-bar>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon
          width="auto"
          class="px-2"
          :aria-label="accessibilityDrawer ? $t('browsing.closeAccessibility') : $t('browsing.expandAccessibility')"
          :title="accessibilityDrawer ? $t('browsing.closeAccessibility') : $t('browsing.expandAccessibility')"
          @click.stop="accessibilityDrawer = !accessibilityDrawer">
          <v-img src="@/assets/icons/ic_accessebilitybuttons.svg"></v-img>
        </v-btn>
        <v-btn @click.stop="closeDrawers()"
          tile
          :aria-label="!drawerOpen ? $t('browsing.expandNavigation') : accessibilityDrawer ? $t('browsing.closeAccessibility') : $t('browsing.closeNavigation')"
          :title="!drawerOpen ? $t('browsing.expandNavigation') : accessibilityDrawer ? $t('browsing.closeAccessibility') : $t('browsing.closeNavigation')"
          icon>
          <v-icon class="text-color-light"> {{ !drawerOpen ? 'mdi-menu' : 'mdi-close' }} </v-icon>
        </v-btn>
      </v-toolbar>
      <!-- Navigation Drawer -->
      <v-navigation-drawer app
        flat
        sticky
        width="100%"
        color="secondary"
        class="mt-14 "
        z-index="8"
        v-model="drawer"
        absolute
        :clipped="true"
        hide-overlay>
        <div class="hidden-sm-and-up" >
        <search-bar class="px-4 mt-4 "
          :scrollOnFocus="false"
          :attachClose="false"
          :menuZIndex="8"
          fullHeight></search-bar>
          </div>
        <v-list>
          <v-list-item v-for="category in $store.state.headerCategories"
            :key="category.id"
            @click="toRoute(category)">
            <v-list-item-title class="text-subtitle text-color-light  pr-5"
              style="flex: none;"> {{ category[$dyncMessages.locale] }} </v-list-item-title>
            <v-icon color="ctaLight">mdi-arrow-right</v-icon>
          </v-list-item>
        </v-list>
        <div class="text-caption-1 ml-4 text-color-light"><small>{{ $t('browsing.selectLanguage') }}</small></div>
        <v-select height="45"
          :label="$t('browsing.selectLanguage')"
          hide-no-data
          solo
          dense
          outlined
          :value="this.$dyncMessages.locale"
          :items="$store.state.languages"
          :aria-label="$t('browsing.changeLanguage')"
          item-text="title"
          append-icon="mdi-chevron-down"
          @input="$emit('changeLanguage', $event)"
          class="mx-4" />
      </v-navigation-drawer>
      <!-- Accessibility Drawer -->
      <v-navigation-drawer app
        flat
        sticky
        width="100%"
        color="secondary"
        class="mt-14"
        z-index="8"
        v-model="accessibilityDrawer"
        absolute
        :clipped="true"
        hide-overlay>
        <h3 class="py-10 px-4 text-color-light">{{ $t('browsing.accessibilityTitle') }}</h3>

        <body class="px-4 text-color-light "> <b>{{ $t("browsing.textSize") }}</b></body>
        <div class="pl-2 mt-2">
          <text-size-selector :onMobile="true"
            @clicked="closeDrawers"></text-size-selector>
        </div>

        <body class="px-4 text-color-light "
          style="margin-top: 75px;"> <b>{{ $t("browsing.contrast") }}</b></body>
        <div class=" mt-4 mb-8 px-4">
          <color-selector :onMobile="true"
            @clicked="closeDrawers"></color-selector>
        </div>
      </v-navigation-drawer>
    </v-app-bar>
  </div>
</template>

<script>
import SearchBar from './SearchBar.vue';
import TextSizeSelector from './TextSizeSelector.vue';
import ColorSelector from './ColorSelector.vue';

export default {
  data() {
    return {
      drawer: false,
      accessibilityDrawer: false,
      group: [],
      subfolder: false,
    }
  },
  computed: {
    drawerOpen() {
      if (this.drawer || this.accessibilityDrawer) {
        return true
      }
      return false
    }
  },
  methods: {
    toRoute(item) {
      this.$router.push({ name: `category`, params: { itemId: item.id } })
      this.closeDrawers()
    },
    closeDrawers() {
      //ToDo: close drawer when option has been selected
      if (this.accessibilityDrawer) {
        this.accessibilityDrawer = false
        this.drawer = false
        return
      }
      this.drawer = !this.drawer
    },
    articles(category) {
      let lanArticles = category[this.$dyncMessages.locale + 'articles'] || []
      let generalArticles = category['generalarticles']
      let articles = lanArticles.concat(generalArticles)
      return articles
    }
  },
  components: {
    SearchBar,
    TextSizeSelector,
    ColorSelector
  }
}
</script>

<style scoped >
.appBarclass:deep .v-toolbar__content {
  padding: 0;
}

.v-select:deep .v-icon {
  color: 'ctaDark';
}

b {
  font-weight: 900 !important;
}

.v- .new-title {
  color: white !important;
  font-family: 'Helvetica', serif !important;
  line-height: 120%;
  font-size: 24px !important;
  border-radius: 0px !important;
  font-weight: bolder;
}
</style>
