import Vue from 'vue';
import DyncMessages from 'dync-messages';
import en from '../messages/en';
import sv from '../messages/sv';
import vuetifyEn from 'vuetify/lib/locale/en';
import vuetifySv from 'vuetify/lib/locale/sv';

Vue.use(DyncMessages);

const messages = {
  en: {
    $vuetify: vuetifyEn,
    ...en
  },
  sv: {
    $vuetify: vuetifySv,
    ...sv
  }
};

export default DyncMessages.init(Vue, {
  messages,
  locale: 'sv',
  fallbackLocale: 'en',
  silentFallback: false,
  fetchMessages: false,
  dataObject: 'web'
});
