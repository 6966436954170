<template>
  <div>
    <v-btn @click="setColor('standard')"
      :aria-label="$t('browsing.setColorTheme.standard')"
      :title="$t('browsing.setColorTheme.standard')"
      class="mr-12 mr-lg-2 mt-3"
      color="black"
      :style="{
        fontSize: '1.5rem',
        opacity: $store.state.colorTheme === 'standard' ?'100%' : '75%'
      }"
      icon
      :large="onMobile" fab>
      <v-img src="@/assets/icons/ic_standard.svg" :height="!onMobile ? 42 : 'auto'"
        contain></v-img>
    </v-btn>
    <v-btn @click="setColor('greyscale')"
      :aria-label="$t('browsing.setColorTheme.greyscale')"
      :title="$t('browsing.setColorTheme.greyscale')"
      class="mr-12 mr-lg-2 mt-3"
      :style="{
        fontSize: '1.5rem',
        opacity: $store.state.colorTheme === 'greyscale' ?'100%' : '75%'
      }"

      
      :large="onMobile" 
      icon
      fab>
      <v-img src="@/assets/icons/ic_greyscale.svg" :height="!onMobile ? 42 : 'auto'"
        contain></v-img>
    </v-btn>
    <v-btn @click="setColor('yellow-black')"
      :aria-label="$t('browsing.setColorTheme.yellowBlack')"
      :title="$t('browsing.setColorTheme.yellowBlack')"
      class="mr-12 mr-lg-0 mt-3"
      :style="{
        fontSize: '1.5rem',
        opacity: $store.state.colorTheme === 'yellow-black' ?'100%' : '75%'
      }"
      icon
      :large="onMobile"
      
      fab>
      <v-img src="@/assets/icons/ic_yellowblack.svg" :height="!onMobile ? 42 : 'auto'"
        contain></v-img>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ColorSelector",
  props: {
    onMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    setColor(value) {
      this.$store.commit("setColorTheme", value)
      this.$emit("clicked", value)
    }
  }
};
</script>


