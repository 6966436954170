<template>
    <div class=" text-body-1">
        <!-- TODO: Language support in this view -->
        <v-container class="maxglobalwidth" fluid>
            <v-row class="mb-10">
                <v-col class="d-none d-lg-flex"
                    cols="12" md="5">
                    <v-img src="@/assets/heroes/about_image_2.jpg"
                        aspect-ratio="1.2"></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12"
                    md="6" align-self="center">
                    
                    <h1 v-html="activeText.title1 || sv.title1"></h1>
                   
                </v-col>
                <v-col class="d-md-flex d-lg-none">
                    <v-img src="@/assets/heroes/about_image_2.jpg"
                        max-height="600"
                        max-width="600"></v-img>
                </v-col>
            </v-row>
            <v-row class="my-10">
                <v-col cols="12"
                    md="6">
                    <div class="text-body-1" v-html="activeText.section1 || sv.section1"/>
                    
                </v-col>
            </v-row>
            <v-row class="my-10">
                <v-col class="d-md-flex d-lg-none" cols="12" md="6">
                    <v-img src="@/assets/heroes/about_image.jpg"
                        aspect-ratio="1.5"></v-img>
                </v-col>
                <v-col cols="12"
                    md="5" align-self="center">
                    <h3 class="my-4" v-html="activeText.title2 || sv.title2"></h3>
                    <div class="text-body-1" v-html="activeText.section2 || sv.section2"/>                    
                    
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-none d-lg-flex" cols="12" md="6">
                    <v-img src="@/assets/heroes/about_image.jpg"
                        aspect-ratio="1.5"></v-img>
                </v-col>
            </v-row>
            <v-row class="my-12">
                <v-col md="5" align-self="center"
                    class="my-12">
                    <v-img :src="$store.state.colorTheme == 'yellow-black' ? logoLight : logoDark"
                        max-height="600"
                        max-width="600"></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12"
                    md="6"
                    class="my-12">
                    <h3 class="mb-4" v-html="activeText.title3 || sv.title3"></h3>
                    <div class="text-body-1"  v-html="activeText.section3 || sv.section3" />
                    <v-btn :block="$vuetify.breakpoint.mobile" style="letter-spacing: 1px;" depressed class="mt-12" color="ctaDark btn-text text-color-light" height="48" to="peopleinvolved">{{$t('footer.involved')}}</v-btn>
              
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

export default {
    name: 'AboutUs',
    data() {
        return {
            en: {
                title1: "Sharing information on safe and healthy Para sport since 2022",
                section1: "Participation in sport leads to several health benefits as it improves both physical and mental health. For example, it reduces the risk for cardiometabolic disease, fractures, dementia, mental illness and some cancer types. Sport also supports the development of social skills during different periods in people’s lifetime. Despite all these positive effects, sport also poses the athlete at a risk for suffering a sports-related injury or illness that quite often leads to time loss from sport and performance decreasement. In addition, the Para athlete may be exposed to health problems that are related to the impairment as well as other prerequisites when comparing to able-bodied athletes, which you can read more about in the PhD-thesis  <a href=\"https://portal.research.lu.se/sv/publications/sports-related-injuries-and-illnesses-in-paralympic-athletes-2\">&rdquo;Sports-related injuries and illnesses in Paralympic athletes&rdquo;</a></span></p>",
                title2: "Why did the project start?",
                section2: "Today, successful training-based prevention programmes exist in several sports, but a concern is that many of these programmes are not adapted or accessible to Para athletes and their impairments, sports, equipment and prerequisites. Another concern is that most prevention programmes focus mainly on reducing the risk of injuries, while new research shows that athletes also are exposed to other health issues such as illnesses, mental illness as well as poor sleep and eating habit. <br><br>Positively, recent research suggest that sports safety work should facilitate health promotion, including behavioral changes and education to athletes and coaches. The purpose of the “Safe & Healthy Parasport project” is therefore to develop, implement and evaluate an accessible eHealth based health promotion platform that provides health guidance to Para athletes living and training in different environments. This is also in alignment with the World Health Organization´s global sustainability goals that are to: improve health and well-being, promote education to persons with a disability and to ensure equitable access to health services.",
                title3: "People behind the project",
                section3: "The project is a multi-disciplinary project conducted by researchers from the Rehabilitation Medicine Research Group, Lund University, Sweden and the Sport and Exercise Institute, Stellenbosch University, South Africa. The health promotion material is written by international experts within Parasport and follow the last evidence.<br><br>A complex intervention design has been applied and the project is pursued in direct dialogue with athletes, coaches and stakeholders within the Swedish Paralympic Committee. By also including athletes from South Africa, the project will be involved in the development of safe sport in less developed countries. You can read more about the design and implementation in this study protocol: <a href=\"https://bmjopensem.bmj.com/content/8/3/e001392.info\">Safe and Healthy Para sport project (SHAPE): a study protocol of a complex intervention within Para sport</a><br><br>The project is funded by the Swedish Research Council of Sports Science (CIF), Folksam, Promobilia, Norrbacka Eugenia stiftelsen, Lund University and the Swedish Paralympic Committee."    
            },
            sv: {
                title1: "Sprider information om säker och hälsosam Parasport sedan 2022",
                section1: "<p>Att delta i idrott f&ouml;rb&auml;ttrar b&aring;de den fysiska och psykiska h&auml;lsan. Till exempel minskar risken f&ouml;r hj&auml;rt- och k&auml;rlsjukdomar, frakturer, demens, psykisk oh&auml;lsa och vissa typer av cancer. Idrottsdeltagande leder &auml;ven till att vi som m&auml;nniskor utvecklar sociala f&ouml;rm&aring;gor under olika perioder i v&aring;ra liv. F&ouml;r personer med en funktionsneds&auml;ttning &auml;r betydelsen av idrott extra stor d&aring; funktionsneds&auml;ttningen i sig ibland &ouml;kar risken f&ouml;r vissa skador och sjukdomar. <br><br >Trots alla dessa positiva effekter inneb&auml;r idrottdeltagande ocks&aring; en &ouml;kad risk f&ouml;r att drabbas av en idrottsrelaterad skada, sjukdom eller annan oh&auml;lsa. Detta leder ofta till att idrottaren f&ouml;rlorar tid fr&aring;n idrotten och att prestationen f&ouml;rs&auml;mras. Paraidrottare har &auml;ven en &ouml;kad risk f&ouml;r att drabbas av idrottsrelaterade skador och sjukdomar som &auml;r relaterade till sj&auml;lva funktionsneds&auml;ttningen, vilket du kan l&auml;sa mer om i avhandlingen <a href=\"https://portal.research.lu.se/sv/publications/sports-related-injuries-and-illnesses-in-paralympic-athletes-2\">&rdquo;Sports-related injuries and illnesses in Paralympic athletes&rdquo;</a></span></p>",
                title2: "Varför startade detta projektet?",
                section2: "Idag finns det framgångsrika träningsbaserade förebyggande program inom flera idrotter, men ett problem är att många av dessa program inte är anpassade eller tillgängliga för paraidrottare och deras funktionsnedsättningar, idrotter, utrustning och förutsättningar. <br>Ett annat problem är att de flesta förebyggande program främst fokuserar på att minska risken för skador, medan ny forskning visar att idrottare också utsätts för andra hälsoproblem, t.ex. sjukdomar, psykisk ohälsa samt dålig sömn och obalanserad kost.<br> <br>Positivt är att ny forskning tyder på att hälsofrämjande åtgärder såsom utbildning av idrottare och tränare samt beteendeförändringar kan förbättra idrottares hälsa. Syftet med projektet \"Säker & Hälsosam Parasport\" är därför att utveckla och utvärdera en tillgänglig e-hälsopromotionsplattform specifikt anpassad till paraidrottare. Detta är också i linje med Världshälsoorganisationens globala hållbarhetsmål som är att förbättra hälsa och främja utbildning hos personer med funktionsnedsättning.",
                title3: "Vi som driver projektet",
                section3: "Projektet är ett tvärvetenskapligt projekt som drivs av forskare från forskargruppen Rehabiliteringsmedicin vid Lunds universitet i samarbete med Sport and Exercise Institute vid Stellenbosch University, Sydafrika. Den tillgängliga webbsidan är utvecklad av webbdesignstudenter från Blekinge Tekniska Högskola i samarbete med Blekinge Sports Tech Initative och företaget WIP. Andra samarbetspartners är Sveriges Paralympiska Kommitté, Parasport Sverige och Gymnastik- och Idrottshögskolan (GIH). Allt materialet är skrivet av nationella och internationella experter inom Parasport enligt den senaste evidensen.<br><br>En komplex interventionsdesign har tillämpats och projektet har utvecklats i direkt dialog med idrottare, tränare och representanter från Sveriges Paralympiska Kommitté. Du kan läsa mer om projektdesignen i detta studieprotokoll: <a href=\"https://bmjopensem.bmj.com/content/8/3/e001392.info\">Safe and Healthy Para sport project (SHAPE): a study protocol of a complex intervention within Para sport</a><br><br>Projektet finansieras av Centrum för Idrottsforskning, Folksam, Promobilia, Norrbacka Eugenia stiftelsen, Lunds universitet och Sveriges Paralympiska Kommitté."    
            },
            logoDark: require('@/assets/im_logo_dark.svg'),
            logoLight: require('@/assets/im_header_logo.svg')
        };
    },
    computed: {
        activeText() {
            return this[this.$dyncMessages.locale] || this.sv
        },
        local(){
            return this.$dyncMessages.locale
        }
    }
}
</script>

<style lang="css" scoped>

</style>
