<template class="">
  <div>
    <v-container fluid
      class="maxglobalwidth">
      <div>
        <v-row no-gutters>
          <v-col cols="12"
            md="7">
            <h1>{{ $t('home.title') }}</h1>
            <div class="text-body-large mt-4"
              v-html="$t('home.text')"></div>
          </v-col>
        </v-row>
        <!-- Search bar -->
        <v-row wrap
          class="align-top mt-10">
          <v-col cols="12"
            md="7"
            class="pt-0">
            <SearchBar ref="search"
              :dark="false"></SearchBar>
          </v-col>
        </v-row>
      </div>
      <!-- Hero images -->
      <v-carousel class="mt-16"
        cycle
        :show-arrows="carouselItems.length > 1"
        :hide-delimiters="carouselItems.length <= 1"
        @change="activeCarouselItem = carouselItems[$event]"
        :height="$vuetify.breakpoint.mdAndUp ? 700 : 400">
        <v-carousel-item v-for="(item, i) in carouselItems"
          :key="i"
          :src="item.image"></v-carousel-item>
      </v-carousel>
      <div v-if="activeCarouselItem"
        class="text-color-secondary text-caption-1 mt-2"
        style="min-height: 2rem;"> {{ activeCarouselItem.description[$dyncMessages.locale] }} </div>
      <!-- Categories -->
      <div >
        <h2 class="text-center"
        
        style="margin-top: 120px;"
        ref="test">{{ $t('home.not_sure_title') }}</h2>
        
        <CategoryCards :articles="recomendedItems"></CategoryCards>
      </div>
    </v-container>
    <!-- Partners -->
    <v-sheet color="#e9eaeb"
      style="padding-top: 120px; padding-bottom: 120px ;margin-top: 120px; position: relative;"
      class=" mx-sm-n3 mx-md-n6 mx-lg-n11 mb-n16">
      <v-container>
        <v-row align="center"
          align-content="center"
          justify="center"
          dense>
          <v-col cols="10">
            <v-row justify="center"
              align-content="center">
              <v-col v-for="(item, i) in partnerItems"
                align-self="center"
                :key="i"
                cols="4"
                sm="4"
                lg="3"
                class="mb-2">
                <a :href="item.link"
                  target="_blank"
                  class="d-block">
                  <v-img :src="item.logo"
                    :alt="item.description"
                    contain
                    max-height="150" />
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

    </v-sheet>
    <!-- Instagram -->
    <v-container style="margin-bottom: 7rem;" v-if="posts.length" class="mt-16">
      <h3 class="text-center"
        style="margin-top: 106px; margin-bottom: 40px;"
        ref="test">{{ $t('home.followinstagram') }}</h3>
      <v-row no-gutters class="row">
        <v-col v-for="item, n in posts"        
          :key="n"
          class="collum"
          cols="6"
          md="6"
          lg="3">
          <a :href="item.permalink"
            alt="Open post in instagram"
            target="_blank">
            <v-img :src="item.image_url"
              aspect-ratio="1"
              cover
              :alt="'Post text: ' +item.text"
              class="bg-grey-lighten-2">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0"
                  align="center"
                  justify="center">
                  <v-progress-circular indeterminate
                    color="grey-lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import CategoryCards from '@/components/CategoryCards.vue'
export default
  {
    name: 'Home',
    data() {
      return {
        partnerItems: [
          {
            logo: require('../assets/partners/lunds-universitet-logo-svg-vector.svg'),
            link: "https://www.lunduniversity.lu.se/",
            description: 'lunds university'
          },
          {
            logo: require('../assets/partners/BTH-logo.png'),
            link: "https://www.bth.se/",
            description: 'Blekinge Tekniska Högskola'
          },
          {
            logo: require('../assets/partners/centrumforidrottsforksning.svg'),
            link: "https://centrumforidrottsforskning.se/",
            description: 'Centrum för idrottsforksning'
          },
          {
            logo: require('../assets/partners/SU-Horiz-logo.png'),
            link: "https://www.sun.ac.za/Home.aspx",
            description: 'Stellenbosch Universitet'
          },
          {
            logo: require('../assets/partners/SPK.png'),
            link: "https://paralympics.se/",
            description: 'Sveriges paralympiska kommitté'
          },
          {
            logo: require('../assets/partners/parasport_rgb_logo2.png'),
            link: "https://www.parasport.se/",
            description: 'Parasport Sverige'
          },
          {
            logo: require('../assets/partners/1200px-Folksam_logo.svg_.png'),
            link: "https://www.folksam.se/",
            description: 'Folksam'
          }
        ],
        carouselItems: [
          {
            image: require('../assets/heroes/hero_image_1.jpg'),
            description: {
              sv: 'En paraidrottare med underbensprotes som springer snabbt på en friidrottsbana.',
              en: 'A para athlete with lower leg amputation running on a track.'
            }
          }, {
            image: require('../assets/heroes/hero_image_2.jpg'),
            description: {
              en: 'A judoka with vision impairment fighting with another athlete.',
              sv: 'En judoka med synnedsättning som fightas.'
            }

          }, {
            image: require('../assets/heroes/hero_image_3.jpg')
            ,
            description: {
              en: 'A para alpine skier with a upper arm prosthesis skiing downhill.',
              sv: 'En paraidrottare på alpina skidor som åker snabbt ner för en brant backe.'
            }
          }, {
            image: require('../assets/heroes/hero_image_4.jpg'),
            description: {
              sv: 'Fem paraidrottare som spelar rullstolsinnebandy.',
              en: 'Five para athletes playing wheelchair bandy.'
            }
          }
        ],
        activeCarouselItem: null
      }
    },
    methods: {
      jumpToSearch() {
        var element = this.$refs.search.$el;
        var headerOffset = 130;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });

        setTimeout(() => {
          this.$refs.search.focus();
        }, 500);
      }
    },
    computed: {
      posts() {
        return this.$store.state.dyncSocial.socialFeed.slice(0,4)
      },
      recomendedItems() {
        return this.$store.state.simpleArticles.filter(item => {
          return item.showonlandingpage === "True" && this.$dyncMessages.locale == item.language
        })
      }
    },
    mounted() {
      this.$store.dispatch('dyncSocial/getSocialFeed')
    },
    components:
    {
      SearchBar,
      CategoryCards
    }
  }
</script>

<style lang="scss" scoped>
@import "../scss/row-gutters.scss";
</style>
